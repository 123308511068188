import { PlusOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import React from "react";
import DynamicColumnsTable from "../helper/DynamicColumnsTable";
import { calculateColumnsWidth } from "../helper/DynamicColumnsHelper";

import "./styles/PowerRankingsTable.scss";

const data = [
  {
    id: 24,
    name: "Anaheim Ducks",
    venue: "Honda Center",
    city: "Anaheim",
    abbreviation: "ANA",
    teamName: "Ducks",
    locationName: "Anaheim",
    division: "Pacific",
    divisionId: 15,
    conference: "Western",
    conferenceId: 5,
    ptPctg: 39.4,
    goalsPerGame: 2.549,
    goalsAgainstPerGame: 4.014,
    evGGARatio: 0.6289,
    powerPlayPercentage: 16.2,
    penaltyKillPercentage: 72.4,
    shotsPerGame: 28.662,
    shotsAllowed: 39.1408,
    faceOffWinPercentage: 46.3,
    shootingPctg: 8.9,
    savePctg: 0.897,
    teamId: 24,
    opposingTeamId: 24,
    PowerScore: 0.28079018,
    change: "up",
  },
  {
    id: 29,
    name: "Columbus Blue Jackets",
    venue: "Nationwide Arena",
    city: "Columbus",
    abbreviation: "CBJ",
    teamName: "Blue Jackets",
    locationName: "Columbus",
    division: "Metropolitan",
    divisionId: 18,
    conference: "Eastern",
    conferenceId: 6,
    ptPctg: 36.4,
    goalsPerGame: 2.686,
    goalsAgainstPerGame: 3.886,
    evGGARatio: 0.6667,
    powerPlayPercentage: 19.0,
    penaltyKillPercentage: 76.3,
    shotsPerGame: 29.8714,
    shotsAllowed: 35.2429,
    faceOffWinPercentage: 48.2,
    shootingPctg: 9.0,
    savePctg: 0.89,
    teamId: 29,
    opposingTeamId: 29,
    PowerScore: 0.3070524633,
    change: "up",
  },
  {
    id: 16,
    name: "Chicago Blackhawks",
    venue: "United Center",
    city: "Chicago",
    abbreviation: "CHI",
    teamName: "Blackhawks",
    locationName: "Chicago",
    division: "Central",
    divisionId: 16,
    conference: "Western",
    conferenceId: 5,
    ptPctg: 38.6,
    goalsPerGame: 2.471,
    goalsAgainstPerGame: 3.529,
    evGGARatio: 0.6705,
    powerPlayPercentage: 16.6,
    penaltyKillPercentage: 76.6,
    shotsPerGame: 26.6857,
    shotsAllowed: 33.9714,
    faceOffWinPercentage: 53.3,
    shootingPctg: 9.3,
    savePctg: 0.896,
    teamId: 16,
    opposingTeamId: 16,
    PowerScore: 0.3283390701,
    change: "up",
  },
  {
    id: 8,
    name: "Montreal Canadiens",
    venue: "Bell Centre",
    city: "Montreal",
    abbreviation: "MTL",
    teamName: "Canadiens",
    locationName: "Montreal",
    division: "Atlantic",
    divisionId: 17,
    conference: "Eastern",
    conferenceId: 6,
    ptPctg: 43.7,
    goalsPerGame: 2.775,
    goalsAgainstPerGame: 3.69,
    evGGARatio: 0.8412,
    powerPlayPercentage: 16.5,
    penaltyKillPercentage: 72.1,
    shotsPerGame: 27.3239,
    shotsAllowed: 34.0845,
    faceOffWinPercentage: 48.8,
    shootingPctg: 10.2,
    savePctg: 0.892,
    teamId: 8,
    opposingTeamId: 8,
    PowerScore: 0.3328647912,
    change: "up",
  },
  {
    id: 28,
    name: "San Jose Sharks",
    venue: "SAP Center at San Jose",
    city: "San Jose",
    abbreviation: "SJS",
    teamName: "Sharks",
    locationName: "San Jose",
    division: "Pacific",
    divisionId: 15,
    conference: "Western",
    conferenceId: 5,
    ptPctg: 37.3,
    goalsPerGame: 2.859,
    goalsAgainstPerGame: 3.775,
    evGGARatio: 0.8043,
    powerPlayPercentage: 17.8,
    penaltyKillPercentage: 82.7,
    shotsPerGame: 29.9718,
    shotsAllowed: 31.9014,
    faceOffWinPercentage: 50.6,
    shootingPctg: 9.5,
    savePctg: 0.882,
    teamId: 28,
    opposingTeamId: 28,
    PowerScore: 0.3360220194,
    change: "up",
  },
  {
    id: 4,
    name: "Philadelphia Flyers",
    venue: "Wells Fargo Center",
    city: "Philadelphia",
    abbreviation: "PHI",
    teamName: "Flyers",
    locationName: "Philadelphia",
    division: "Metropolitan",
    divisionId: 18,
    conference: "Eastern",
    conferenceId: 6,
    ptPctg: 45.7,
    goalsPerGame: 2.657,
    goalsAgainstPerGame: 3.3,
    evGGARatio: 0.922,
    powerPlayPercentage: 15.4,
    penaltyKillPercentage: 73.6,
    shotsPerGame: 29.2714,
    shotsAllowed: 31.5857,
    faceOffWinPercentage: 45.6,
    shootingPctg: 9.1,
    savePctg: 0.896,
    teamId: 4,
    opposingTeamId: 4,
    PowerScore: 0.3478661776,
    change: "up",
  },
  {
    id: 53,
    name: "Arizona Coyotes",
    venue: "Mullett Arena",
    city: "Tempe",
    abbreviation: "ARI",
    teamName: "Coyotes",
    locationName: "Arizona",
    division: "Central",
    divisionId: 16,
    conference: "Western",
    conferenceId: 5,
    ptPctg: 45.8,
    goalsPerGame: 2.775,
    goalsAgainstPerGame: 3.423,
    evGGARatio: 0.8553,
    powerPlayPercentage: 19.2,
    penaltyKillPercentage: 76.7,
    shotsPerGame: 25.8028,
    shotsAllowed: 35.7042,
    faceOffWinPercentage: 45.6,
    shootingPctg: 10.8,
    savePctg: 0.904,
    teamId: 53,
    opposingTeamId: 53,
    PowerScore: 0.3680305779,
    change: "up",
  },
  {
    id: 23,
    name: "Vancouver Canucks",
    venue: "Rogers Arena",
    city: "Vancouver",
    abbreviation: "VAN",
    teamName: "Canucks",
    locationName: "Vancouver",
    division: "Pacific",
    divisionId: 15,
    conference: "Western",
    conferenceId: 5,
    ptPctg: 47.9,
    goalsPerGame: 3.3,
    goalsAgainstPerGame: 3.714,
    evGGARatio: 0.8497,
    powerPlayPercentage: 22.8,
    penaltyKillPercentage: 69.6,
    shotsPerGame: 30.2857,
    shotsAllowed: 30.8429,
    faceOffWinPercentage: 49.2,
    shootingPctg: 10.9,
    savePctg: 0.88,
    teamId: 23,
    opposingTeamId: 23,
    PowerScore: 0.3915786147,
    change: "up",
  },
  {
    id: 19,
    name: "St. Louis Blues",
    venue: "Enterprise Center",
    city: "St. Louis",
    abbreviation: "STL",
    teamName: "Blues",
    locationName: "St. Louis",
    division: "Central",
    divisionId: 16,
    conference: "Western",
    conferenceId: 5,
    ptPctg: 48.6,
    goalsPerGame: 3.1,
    goalsAgainstPerGame: 3.614,
    evGGARatio: 0.8817,
    powerPlayPercentage: 20.3,
    penaltyKillPercentage: 76.4,
    shotsPerGame: 28.6286,
    shotsAllowed: 32.4714,
    faceOffWinPercentage: 49.2,
    shootingPctg: 10.8,
    savePctg: 0.889,
    teamId: 19,
    opposingTeamId: 19,
    PowerScore: 0.4220335484,
    change: "up",
  },
  {
    id: 5,
    name: "Pittsburgh Penguins",
    venue: "PPG Paints Arena",
    city: "Pittsburgh",
    abbreviation: "PIT",
    teamName: "Penguins",
    locationName: "Pittsburgh",
    division: "Metropolitan",
    divisionId: 18,
    conference: "Eastern",
    conferenceId: 6,
    ptPctg: 55.7,
    goalsPerGame: 3.186,
    goalsAgainstPerGame: 3.243,
    evGGARatio: 0.9474,
    powerPlayPercentage: 21.2,
    penaltyKillPercentage: 79.7,
    shotsPerGame: 34.2,
    shotsAllowed: 33.1857,
    faceOffWinPercentage: 52.4,
    shootingPctg: 9.3,
    savePctg: 0.902,
    teamId: 5,
    opposingTeamId: 5,
    PowerScore: 0.4576529264,
    change: "up",
  },
  {
    id: 9,
    name: "Ottawa Senators",
    venue: "Canadian Tire Centre",
    city: "Ottawa",
    abbreviation: "OTT",
    teamName: "Senators",
    locationName: "Ottawa",
    division: "Atlantic",
    divisionId: 17,
    conference: "Eastern",
    conferenceId: 6,
    ptPctg: 51.4,
    goalsPerGame: 3.085,
    goalsAgainstPerGame: 3.239,
    evGGARatio: 0.8025,
    powerPlayPercentage: 23.7,
    penaltyKillPercentage: 82.4,
    shotsPerGame: 33.2535,
    shotsAllowed: 31.9014,
    faceOffWinPercentage: 51.5,
    shootingPctg: 9.3,
    savePctg: 0.898,
    teamId: 9,
    opposingTeamId: 9,
    PowerScore: 0.4607606232,
    change: "up",
  },
  {
    id: 18,
    name: "Nashville Predators",
    venue: "Bridgestone Arena",
    city: "Nashville",
    abbreviation: "NSH",
    teamName: "Predators",
    locationName: "Nashville",
    division: "Central",
    divisionId: 16,
    conference: "Western",
    conferenceId: 5,
    ptPctg: 56.5,
    goalsPerGame: 2.812,
    goalsAgainstPerGame: 2.942,
    evGGARatio: 1.0236,
    powerPlayPercentage: 18.1,
    penaltyKillPercentage: 81.7,
    shotsPerGame: 30.1739,
    shotsAllowed: 33.2464,
    faceOffWinPercentage: 52.2,
    shootingPctg: 9.3,
    savePctg: 0.912,
    teamId: 18,
    opposingTeamId: 18,
    PowerScore: 0.4612570107,
    change: "up",
  },
  {
    id: 17,
    name: "Detroit Red Wings",
    venue: "Little Caesars Arena",
    city: "Detroit",
    abbreviation: "DET",
    teamName: "Red Wings",
    locationName: "Detroit",
    division: "Atlantic",
    divisionId: 17,
    conference: "Eastern",
    conferenceId: 6,
    ptPctg: 50.7,
    goalsPerGame: 2.9,
    goalsAgainstPerGame: 3.257,
    evGGARatio: 0.8311,
    powerPlayPercentage: 20.9,
    penaltyKillPercentage: 78.2,
    shotsPerGame: 28.7571,
    shotsAllowed: 30.5143,
    faceOffWinPercentage: 49.1,
    shootingPctg: 10.1,
    savePctg: 0.893,
    teamId: 17,
    opposingTeamId: 17,
    PowerScore: 0.4654855728,
    change: "up",
  },
  {
    id: 15,
    name: "Washington Capitals",
    venue: "Capital One Arena",
    city: "Washington",
    abbreviation: "WSH",
    teamName: "Capitals",
    locationName: "Washington",
    division: "Metropolitan",
    divisionId: 18,
    conference: "Eastern",
    conferenceId: 6,
    ptPctg: 51.4,
    goalsPerGame: 3.125,
    goalsAgainstPerGame: 3.097,
    evGGARatio: 0.9563,
    powerPlayPercentage: 21.3,
    penaltyKillPercentage: 82.7,
    shotsPerGame: 31.5139,
    shotsAllowed: 30.5,
    faceOffWinPercentage: 50.2,
    shootingPctg: 9.9,
    savePctg: 0.898,
    teamId: 15,
    opposingTeamId: 15,
    PowerScore: 0.5016098619,
    change: "up",
  },
  {
    id: 2,
    name: "New York Islanders",
    venue: "UBS Arena",
    city: "Elmont",
    abbreviation: "NYI",
    teamName: "Islanders",
    locationName: "New York",
    division: "Metropolitan",
    divisionId: 18,
    conference: "Eastern",
    conferenceId: 6,
    ptPctg: 56.9,
    goalsPerGame: 2.986,
    goalsAgainstPerGame: 2.681,
    evGGARatio: 1.2016,
    powerPlayPercentage: 16.3,
    penaltyKillPercentage: 82.2,
    shotsPerGame: 30.5139,
    shotsAllowed: 31.3333,
    faceOffWinPercentage: 50.8,
    shootingPctg: 9.8,
    savePctg: 0.914,
    teamId: 2,
    opposingTeamId: 2,
    PowerScore: 0.5094915032,
    change: "up",
  },
  {
    id: 20,
    name: "Calgary Flames",
    venue: "Scotiabank Saddledome",
    city: "Calgary",
    abbreviation: "CGY",
    teamName: "Flames",
    locationName: "Calgary",
    division: "Pacific",
    divisionId: 15,
    conference: "Western",
    conferenceId: 5,
    ptPctg: 54.9,
    goalsPerGame: 3.139,
    goalsAgainstPerGame: 3.083,
    evGGARatio: 1.0748,
    powerPlayPercentage: 20.1,
    penaltyKillPercentage: 81.6,
    shotsPerGame: 35.4583,
    shotsAllowed: 27.1944,
    faceOffWinPercentage: 50.8,
    shootingPctg: 8.9,
    savePctg: 0.887,
    teamId: 20,
    opposingTeamId: 20,
    PowerScore: 0.515011251,
    change: "up",
  },
  {
    id: 30,
    name: "Minnesota Wild",
    venue: "Xcel Energy Center",
    city: "St. Paul",
    abbreviation: "MIN",
    teamName: "Wild",
    locationName: "Minnesota",
    division: "Central",
    divisionId: 16,
    conference: "Western",
    conferenceId: 5,
    ptPctg: 63.4,
    goalsPerGame: 2.887,
    goalsAgainstPerGame: 2.648,
    evGGARatio: 1.0513,
    powerPlayPercentage: 22.2,
    penaltyKillPercentage: 80.5,
    shotsPerGame: 31.493,
    shotsAllowed: 30.7324,
    faceOffWinPercentage: 47.6,
    shootingPctg: 9.2,
    savePctg: 0.914,
    teamId: 30,
    opposingTeamId: 30,
    PowerScore: 0.5199126005,
    change: "up",
  },
  {
    id: 7,
    name: "Buffalo Sabres",
    venue: "KeyBank Center",
    city: "Buffalo",
    abbreviation: "BUF",
    teamName: "Sabres",
    locationName: "Buffalo",
    division: "Atlantic",
    divisionId: 17,
    conference: "Eastern",
    conferenceId: 6,
    ptPctg: 51.4,
    goalsPerGame: 3.571,
    goalsAgainstPerGame: 3.729,
    evGGARatio: 0.9266,
    powerPlayPercentage: 24.6,
    penaltyKillPercentage: 72.2,
    shotsPerGame: 32.2,
    shotsAllowed: 33.0429,
    faceOffWinPercentage: 45.2,
    shootingPctg: 11.1,
    savePctg: 0.887,
    teamId: 7,
    opposingTeamId: 7,
    PowerScore: 0.5216616988,
    change: "up",
  },
  {
    id: 13,
    name: "Florida Panthers",
    venue: "FLA Live Arena",
    city: "Sunrise",
    abbreviation: "FLA",
    teamName: "Panthers",
    locationName: "Florida",
    division: "Atlantic",
    divisionId: 17,
    conference: "Eastern",
    conferenceId: 6,
    ptPctg: 55.6,
    goalsPerGame: 3.493,
    goalsAgainstPerGame: 3.38,
    evGGARatio: 1.1857,
    powerPlayPercentage: 22.5,
    penaltyKillPercentage: 74.5,
    shotsPerGame: 36.9718,
    shotsAllowed: 31.5915,
    faceOffWinPercentage: 48.6,
    shootingPctg: 9.4,
    savePctg: 0.893,
    teamId: 13,
    opposingTeamId: 13,
    PowerScore: 0.524889946,
    change: "up",
  },
  {
    id: 14,
    name: "Tampa Bay Lightning",
    venue: "AMALIE Arena",
    city: "Tampa",
    abbreviation: "TBL",
    teamName: "Lightning",
    locationName: "Tampa Bay",
    division: "Atlantic",
    divisionId: 17,
    conference: "Eastern",
    conferenceId: 6,
    ptPctg: 62.5,
    goalsPerGame: 3.431,
    goalsAgainstPerGame: 3.042,
    evGGARatio: 1.1,
    powerPlayPercentage: 25.5,
    penaltyKillPercentage: 79.8,
    shotsPerGame: 32.1111,
    shotsAllowed: 31.0833,
    faceOffWinPercentage: 51.9,
    shootingPctg: 10.7,
    savePctg: 0.902,
    teamId: 14,
    opposingTeamId: 14,
    PowerScore: 0.5327192545,
    change: "up",
  },
  {
    id: 52,
    name: "Winnipeg Jets",
    venue: "Canada Life Centre",
    city: "Winnipeg",
    abbreviation: "WPG",
    teamName: "Jets",
    locationName: "Winnipeg",
    division: "Central",
    divisionId: 16,
    conference: "Western",
    conferenceId: 5,
    ptPctg: 57.6,
    goalsPerGame: 3.0,
    goalsAgainstPerGame: 2.806,
    evGGARatio: 1.0074,
    powerPlayPercentage: 19.8,
    penaltyKillPercentage: 83.7,
    shotsPerGame: 30.3333,
    shotsAllowed: 30.6667,
    faceOffWinPercentage: 48.8,
    shootingPctg: 9.9,
    savePctg: 0.909,
    teamId: 52,
    opposingTeamId: 52,
    PowerScore: 0.5379987359,
    change: "up",
  },
  {
    id: 54,
    name: "Vegas Golden Knights",
    venue: "T-Mobile Arena",
    city: "Las Vegas",
    abbreviation: "VGK",
    teamName: "Golden Knights",
    locationName: "Vegas",
    division: "Pacific",
    divisionId: 15,
    conference: "Western",
    conferenceId: 5,
    ptPctg: 66.2,
    goalsPerGame: 3.254,
    goalsAgainstPerGame: 2.775,
    evGGARatio: 1.1338,
    powerPlayPercentage: 21.2,
    penaltyKillPercentage: 79.2,
    shotsPerGame: 31.8169,
    shotsAllowed: 30.9296,
    faceOffWinPercentage: 52.6,
    shootingPctg: 10.2,
    savePctg: 0.91,
    teamId: 54,
    opposingTeamId: 54,
    PowerScore: 0.5400823951,
    change: "up",
  },
  {
    id: 25,
    name: "Dallas Stars",
    venue: "American Airlines Center",
    city: "Dallas",
    abbreviation: "DAL",
    teamName: "Stars",
    locationName: "Dallas",
    division: "Central",
    divisionId: 16,
    conference: "Western",
    conferenceId: 5,
    ptPctg: 63.4,
    goalsPerGame: 3.437,
    goalsAgainstPerGame: 2.761,
    evGGARatio: 1.1667,
    powerPlayPercentage: 23.5,
    penaltyKillPercentage: 83.1,
    shotsPerGame: 32.2817,
    shotsAllowed: 30.7465,
    faceOffWinPercentage: 54.8,
    shootingPctg: 10.6,
    savePctg: 0.91,
    teamId: 25,
    opposingTeamId: 25,
    PowerScore: 0.558157146,
    change: "down",
  },
  {
    id: 26,
    name: "Los Angeles Kings",
    venue: "Crypto.com Arena",
    city: "Los Angeles",
    abbreviation: "LAK",
    teamName: "Kings",
    locationName: "Los Angeles",
    division: "Pacific",
    divisionId: 15,
    conference: "Western",
    conferenceId: 5,
    ptPctg: 64.8,
    goalsPerGame: 3.394,
    goalsAgainstPerGame: 3.183,
    evGGARatio: 1.0769,
    powerPlayPercentage: 24.4,
    penaltyKillPercentage: 75.8,
    shotsPerGame: 32.6761,
    shotsAllowed: 27.9437,
    faceOffWinPercentage: 53.0,
    shootingPctg: 10.4,
    savePctg: 0.886,
    teamId: 26,
    opposingTeamId: 26,
    PowerScore: 0.5774341226,
    change: "down",
  },
  {
    id: 55,
    name: "Seattle Kraken",
    venue: "Climate Pledge Arena",
    city: "Seattle",
    abbreviation: "SEA",
    teamName: "Kraken",
    locationName: "Seattle",
    division: "Pacific",
    divisionId: 15,
    conference: "Western",
    conferenceId: 5,
    ptPctg: 60.7,
    goalsPerGame: 3.5,
    goalsAgainstPerGame: 3.2,
    evGGARatio: 1.2806,
    powerPlayPercentage: 19.3,
    penaltyKillPercentage: 74.4,
    shotsPerGame: 30.2286,
    shotsAllowed: 27.7286,
    faceOffWinPercentage: 45.2,
    shootingPctg: 11.6,
    savePctg: 0.885,
    teamId: 55,
    opposingTeamId: 55,
    PowerScore: 0.5915638208,
    change: "down",
  },
  {
    id: 21,
    name: "Colorado Avalanche",
    venue: "Ball Arena",
    city: "Denver",
    abbreviation: "COL",
    teamName: "Avalanche",
    locationName: "Colorado",
    division: "Central",
    divisionId: 16,
    conference: "Western",
    conferenceId: 5,
    ptPctg: 63.8,
    goalsPerGame: 3.275,
    goalsAgainstPerGame: 2.725,
    evGGARatio: 1.1557,
    powerPlayPercentage: 24.8,
    penaltyKillPercentage: 79.3,
    shotsPerGame: 33.1159,
    shotsAllowed: 31.3043,
    faceOffWinPercentage: 46.4,
    shootingPctg: 9.9,
    savePctg: 0.913,
    teamId: 21,
    opposingTeamId: 21,
    PowerScore: 0.6017440557,
    change: "down",
  },
  {
    id: 22,
    name: "Edmonton Oilers",
    venue: "Rogers Place",
    city: "Edmonton",
    abbreviation: "EDM",
    teamName: "Oilers",
    locationName: "Edmonton",
    division: "Pacific",
    divisionId: 15,
    conference: "Western",
    conferenceId: 5,
    ptPctg: 62.0,
    goalsPerGame: 3.93,
    goalsAgainstPerGame: 3.31,
    evGGARatio: 1.0844,
    powerPlayPercentage: 31.1,
    penaltyKillPercentage: 75.7,
    shotsPerGame: 33.3239,
    shotsAllowed: 31.7887,
    faceOffWinPercentage: 50.7,
    shootingPctg: 11.8,
    savePctg: 0.896,
    teamId: 22,
    opposingTeamId: 22,
    PowerScore: 0.6030401587,
    change: "down",
  },
  {
    id: 12,
    name: "Carolina Hurricanes",
    venue: "PNC Arena",
    city: "Raleigh",
    abbreviation: "CAR",
    teamName: "Hurricanes",
    locationName: "Carolina",
    division: "Metropolitan",
    divisionId: 18,
    conference: "Eastern",
    conferenceId: 6,
    ptPctg: 72.5,
    goalsPerGame: 3.304,
    goalsAgainstPerGame: 2.565,
    evGGARatio: 1.2931,
    powerPlayPercentage: 21.2,
    penaltyKillPercentage: 83.6,
    shotsPerGame: 34.942,
    shotsAllowed: 25.8986,
    faceOffWinPercentage: 53.1,
    shootingPctg: 9.5,
    savePctg: 0.901,
    teamId: 12,
    opposingTeamId: 12,
    PowerScore: 0.6138361692,
    change: "down",
  },
  {
    id: 1,
    name: "New Jersey Devils",
    venue: "Prudential Center",
    city: "Newark",
    abbreviation: "NJD",
    teamName: "Devils",
    locationName: "New Jersey",
    division: "Metropolitan",
    divisionId: 18,
    conference: "Eastern",
    conferenceId: 6,
    ptPctg: 69.0,
    goalsPerGame: 3.451,
    goalsAgainstPerGame: 2.662,
    evGGARatio: 1.3125,
    powerPlayPercentage: 20.8,
    penaltyKillPercentage: 82.3,
    shotsPerGame: 34.5634,
    shotsAllowed: 28.2676,
    faceOffWinPercentage: 51.6,
    shootingPctg: 10.0,
    savePctg: 0.906,
    teamId: 1,
    opposingTeamId: 1,
    PowerScore: 0.6139768362,
    change: "down",
  },
  {
    id: 3,
    name: "New York Rangers",
    venue: "Madison Square Garden",
    city: "New York",
    abbreviation: "NYR",
    teamName: "Rangers",
    locationName: "New York",
    division: "Metropolitan",
    divisionId: 18,
    conference: "Eastern",
    conferenceId: 6,
    ptPctg: 64.8,
    goalsPerGame: 3.338,
    goalsAgainstPerGame: 2.704,
    evGGARatio: 1.1642,
    powerPlayPercentage: 23.0,
    penaltyKillPercentage: 80.4,
    shotsPerGame: 31.7606,
    shotsAllowed: 29.3662,
    faceOffWinPercentage: 48.9,
    shootingPctg: 10.5,
    savePctg: 0.908,
    teamId: 3,
    opposingTeamId: 3,
    PowerScore: 0.6201466322,
    change: "nc",
  },
  {
    id: 10,
    name: "Toronto Maple Leafs",
    venue: "Scotiabank Arena",
    city: "Toronto",
    abbreviation: "TOR",
    teamName: "Maple Leafs",
    locationName: "Toronto",
    division: "Atlantic",
    divisionId: 17,
    conference: "Eastern",
    conferenceId: 6,
    ptPctg: 66.4,
    goalsPerGame: 3.4,
    goalsAgainstPerGame: 2.743,
    evGGARatio: 1.2742,
    powerPlayPercentage: 25.7,
    penaltyKillPercentage: 81.0,
    shotsPerGame: 31.7143,
    shotsAllowed: 29.2857,
    faceOffWinPercentage: 53.3,
    shootingPctg: 10.7,
    savePctg: 0.906,
    teamId: 10,
    opposingTeamId: 10,
    PowerScore: 0.627944231,
    change: "nc",
  },
  {
    id: 6,
    name: "Boston Bruins",
    venue: "TD Garden",
    city: "Boston",
    abbreviation: "BOS",
    teamName: "Bruins",
    locationName: "Boston",
    division: "Atlantic",
    divisionId: 17,
    conference: "Eastern",
    conferenceId: 6,
    ptPctg: 80.7,
    goalsPerGame: 3.757,
    goalsAgainstPerGame: 2.114,
    evGGARatio: 1.8125,
    powerPlayPercentage: 22.5,
    penaltyKillPercentage: 86.0,
    shotsPerGame: 32.9286,
    shotsAllowed: 29.7286,
    faceOffWinPercentage: 54.5,
    shootingPctg: 11.4,
    savePctg: 0.929,
    teamId: 6,
    opposingTeamId: 6,
    PowerScore: 0.8281300664,
    change: "nc",
  },
];

const mins = [
  <svg
    width="10"
    height="10"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 10C5 9.44772 5.44772 9 6 9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11L6 11C5.44772 11 5 10.5523 5 10Z"
      className="fill-danger"
    ></path>
  </svg>,
];

const columns = [
  {
    title: "Status",
    dataIndex: "change",
    key: "change",
    width: "80px",
    align: "center",
    fixed: "left",
    render: (a) =>
      a == "up" ? (
        <Avatar
          className="text-success"
          style={{ backgroundColor: "rgba(82,196,26, 0.1)" }}
        >
          <PlusOutlined style={{ fontSize: 10 }} />
        </Avatar>
      ) : a == "down" ? (
        <Avatar
          className="text-danger"
          style={{ backgroundColor: "rgba(245,34,45, 0.1)" }}
        >
          {mins}
        </Avatar>
      ) : (
        <Avatar className="text-warning">
          <span style={{ fontSize: 10 }}>=</span>
        </Avatar>
      ),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    fixed: "left",
    sorter: (a, b) => a.name > b.name,
  },
  {
    title: "Division",
    dataIndex: "division",
    key: "division",
  },
  {
    title: "Conference",
    dataIndex: "conference",
    key: "conference",
  },
  {
    title: "Power Score",
    dataIndex: "PowerScore",
    key: "PowerScore",
    render: (a) => <div>{Math.round(a * 100 * 100) / 100 + "%"}</div>,
    sorter: (a, b) => a.PowerScore - b.PowerScore,
  },
  {
    title: "Goals Per Game",
    dataIndex: "goalsPerGame",
    key: "goalsPerGame",
    sorter: (a, b) => a.goalsPerGame - b.goalsPerGame,
  },
  {
    title: "Goals Against Per Game",
    dataIndex: "goalsAgainstPerGame",
    key: "goalsAgainstPerGame",
    sorter: (a, b) => a.goalsAgainstPerGame - b.goalsAgainstPerGame,
  },
  {
    title: "Faceoff Win %",
    dataIndex: "faceOffWinPercentage",
    key: "faceOffWinPercentage",
    sorter: (a, b) => a.faceOffWinPercentage - b.faceOffWinPercentage,
    render: (a) => Math.round(a * 100) / 100 + "%",
  },
  {
    title: "Faceoff Win %",
    dataIndex: "faceOffWinPercentage",
    key: "faceOffWinPercentage",
    sorter: (a, b) => a.faceOffWinPercentage - b.faceOffWinPercentage,
    render: (a) => Math.round(a * 100) / 100 + "%",
  },
  {
    title: "Point %",
    dataIndex: "ptPctg",
    key: "ptPctg",
    sorter: (a, b) => a.ptPctg - b.ptPctg,
    render: (a) => a + "%",
  },
  {
    title: "Event Strength GA Ratio",
    dataIndex: "evGGARatio",
    key: "evGGARatio",
    sorter: (a, b) => a.evGGARatio - b.evGGARatio,
  },
  {
    title: "Powerplay %",
    dataIndex: "powerPlayPercentage",
    key: "powerPlayPercentage",
    sorter: (a, b) => a.powerPlayPercentage - b.powerPlayPercentage,
    render: (a) => a + "%",
  },
  {
    title: "Penalty Kill %",
    dataIndex: "penaltyKillPercentage",
    key: "penaltyKillPercentage",
    sorter: (a, b) => a.penaltyKillPercentage - b.penaltyKillPercentage,
    render: (a) => a + "%",
  },
  {
    title: "Shots Per Game",
    dataIndex: "shotsPerGame",
    key: "shotsPerGame",
    sorter: (a, b) => a.shotsPerGame - b.shotsPerGame,
    render: (a) => Math.round(a * 100) / 100,
  },
  {
    title: "Shots Allowed Per Game",
    dataIndex: "shotsAllowed",
    key: "shotsAllowed",
    sorter: (a, b) => a.shotsAllowed - b.shotsAllowed,
    render: (a) => Math.round(a * 100) / 100,
  },
  {
    title: "Shooting %",
    dataIndex: "shootingPctg",
    key: "shootingPctg",
    sorter: (a, b) => a.shootingPctg - b.shootingPctg,
    render: (a) => a + "%",
  },
  {
    title: "Save %",
    dataIndex: "savePctg",
    key: "savePctg",
    sorter: (a, b) => a.savePctg - b.savePctg,
    render: (a) => Math.round(a * 100 * 100) / 100 + "%",
  },
];

const PowerRankingsTable = () => {
  const maxWidthPerCell = 600;
  const tableHeight = 500;
  const rowKey = "id";
  const dataTable = calculateColumnsWidth(columns, data, maxWidthPerCell);

  return (
    <div>
      <DynamicColumnsTable
        columns={columns}
        height={tableHeight}
        rowKey={rowKey}
        source={dataTable.source}
        width={dataTable.tableWidth}
      />
    </div>
  );
};

export default PowerRankingsTable;
