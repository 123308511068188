import { Button, Col, Row } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import React from "react";
import { NavLink } from "react-router-dom";

const FeatureCard = (props) => {
  return (
    <div style={{ marginBottom: "5vh", marginTop: "5vh" }}>
      <Row align="middle" justify="center">
        <Col xs={24} sm={24} md={4} lg={4} xl={4} align="middle">
          <p
            style={{
              verticalAlign: "middle",
            }}
          >
            {props.icon}
          </p>
        </Col>
        <Col xs={24} sm={24} md={20} lg={20} xl={20}>
          <Row
            style={{
              fontSize: "12pt",
              marginBottom: "1vh",
              textAlign: "center",
            }}
          >
            <b>{props.name}</b>
          </Row>
          <Row style={{ marginBottom: "1vh" }}>
            <Paragraph style={{ textAlign: "justify" }}>{props.text}</Paragraph>
          </Row>
          <Row>
            <NavLink to={props.path}>
              <Button type="primary" ghost size="small">
                Explore
              </Button>
            </NavLink>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default FeatureCard;
