import { Card } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import React from "react";
import ReactApexChart from "react-apexcharts";

const data = [
  {
    "Unnamed: 0": 916,
    id: 8467950,
    fullName: "Craig Anderson",
    firstName: "Craig",
    lastName: "Anderson",
    primaryNumber: 41.0,
    birthDate: "1981-05-21",
    currentAge: 41.0,
    birthCity: "Park Ridge",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 2\"",
    weight: 187,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 7.0,
    GPI: 0.11840742778541902,
    GOALS_GOTTEN: 28,
  },
  {
    "Unnamed: 0": 906,
    id: 8470594,
    fullName: "Marc-Andre Fleury",
    firstName: "Marc-Andre",
    lastName: "Fleury",
    primaryNumber: 29.0,
    birthDate: "1984-11-28",
    currentAge: 38.0,
    birthCity: "Sorel",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 2\"",
    weight: 185,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 30.0,
    GPI: -0.5704162826953052,
    GOALS_GOTTEN: 49,
  },
  {
    "Unnamed: 0": 903,
    id: 8470860,
    fullName: "Jaroslav Halak",
    firstName: "Jaroslav",
    lastName: "Halak",
    primaryNumber: 41.0,
    birthDate: "1985-05-13",
    currentAge: 37.0,
    birthCity: "Bratislava",
    birthCountry: "SVK",
    nationality: "SVK",
    height: "5' 11\"",
    weight: 189,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 3.0,
    GPI: 0.7604383439271603,
    GOALS_GOTTEN: 21,
  },
  {
    "Unnamed: 0": 898,
    id: 8470880,
    fullName: "Brian Elliott",
    firstName: "Brian",
    lastName: "Elliott",
    primaryNumber: 1.0,
    birthDate: "1985-04-09",
    currentAge: 37.0,
    birthCity: "Newmarket",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 2\"",
    weight: 221,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 14.0,
    GPI: -1.8469304945054956,
    GOALS_GOTTEN: 17,
  },
  {
    "Unnamed: 0": 958,
    id: 8471306,
    fullName: "Thomas Greiss",
    firstName: "Thomas",
    lastName: "Greiss",
    primaryNumber: 1.0,
    birthDate: "1986-01-29",
    currentAge: 37.0,
    birthCity: "Fussen",
    birthCountry: "DEU",
    nationality: "DEU",
    height: "6' 2\"",
    weight: 219,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 19.0,
    GPI: 2.130141241189615,
    GOALS_GOTTEN: 12,
  },
  {
    "Unnamed: 0": 918,
    id: 8471734,
    fullName: "Jonathan Quick",
    firstName: "Jonathan",
    lastName: "Quick",
    primaryNumber: 32.0,
    birthDate: "1986-01-21",
    currentAge: 37.0,
    birthCity: "Milford",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 1\"",
    weight: 220,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 54.0,
    GPI: -0.6807769270743158,
    GOALS_GOTTEN: 46,
  },
  {
    "Unnamed: 0": 957,
    id: 8471774,
    fullName: "Alex Stalock",
    firstName: "Alex",
    lastName: "Stalock",
    primaryNumber: 32.0,
    birthDate: "1987-07-28",
    currentAge: 35.0,
    birthCity: "Saint Paul",
    birthCountry: "USA",
    nationality: "USA",
    height: "5' 11\"",
    weight: 170,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 16.0,
    GPI: -0.11714899742930651,
    GOALS_GOTTEN: 27,
  },
  {
    "Unnamed: 0": 951,
    id: 8473503,
    fullName: "James Reimer",
    firstName: "James",
    lastName: "Reimer",
    primaryNumber: 47.0,
    birthDate: "1988-03-15",
    currentAge: 35.0,
    birthCity: "Morweena",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 3\"",
    weight: 205,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 28.0,
    GPI: 0.6343808599167797,
    GOALS_GOTTEN: 59,
  },
  {
    "Unnamed: 0": 886,
    id: 8473575,
    fullName: "Semyon Varlamov",
    firstName: "Semyon",
    lastName: "Varlamov",
    primaryNumber: 40.0,
    birthDate: "1988-04-27",
    currentAge: 34.0,
    birthCity: "Samara",
    birthCountry: "RUS",
    nationality: "RUS",
    height: "6' 2\"",
    weight: 206,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 2.0,
    GPI: 0.21121794122701587,
    GOALS_GOTTEN: 13,
  },
  {
    "Unnamed: 0": 922,
    id: 8474593,
    fullName: "Jacob Markstrom",
    firstName: "Jacob",
    lastName: "Markstrom",
    primaryNumber: 25.0,
    birthDate: "1990-01-31",
    currentAge: 33.0,
    birthCity: "Gävle",
    birthCountry: "SWE",
    nationality: "SWE",
    height: "6' 6\"",
    weight: 207,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 20.0,
    GPI: 0.46254954829743156,
    GOALS_GOTTEN: 60,
  },
  {
    "Unnamed: 0": 968,
    id: 8474596,
    fullName: "Jake Allen",
    firstName: "Jake",
    lastName: "Allen",
    primaryNumber: 34.0,
    birthDate: "1990-08-07",
    currentAge: 32.0,
    birthCity: "Fredericton",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 2\"",
    weight: 195,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 8.0,
    GPI: 4.087443475238922,
    GOALS_GOTTEN: 70,
  },
  {
    "Unnamed: 0": 937,
    id: 8474682,
    fullName: "Dustin Tokarski",
    firstName: "Dustin",
    lastName: "Tokarski",
    primaryNumber: 40.0,
    birthDate: "1989-09-16",
    currentAge: 33.0,
    birthCity: "Watson",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 0\"",
    weight: 198,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "N",
    primaryPosition: "Goalie",
    currentTeamId: 5.0,
    GPI: 0.6056043686006827,
    GOALS_GOTTEN: 9,
  },
  {
    "Unnamed: 0": 944,
    id: 8474889,
    fullName: "Martin Jones",
    firstName: "Martin",
    lastName: "Jones",
    primaryNumber: 30.0,
    birthDate: "1990-01-10",
    currentAge: 33.0,
    birthCity: "North Vancouver",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 4\"",
    weight: 203,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 55.0,
    GPI: -4.226826120180618,
    GOALS_GOTTEN: 49,
  },
  {
    "Unnamed: 0": 888,
    id: 8475311,
    fullName: "Darcy Kuemper",
    firstName: "Darcy",
    lastName: "Kuemper",
    primaryNumber: 35.0,
    birthDate: "1990-05-05",
    currentAge: 32.0,
    birthCity: "Saskatoon",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 5\"",
    weight: 215,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 15.0,
    GPI: 0.39807912545549407,
    GOALS_GOTTEN: 52,
  },
  {
    "Unnamed: 0": 893,
    id: 8475660,
    fullName: "Cam Talbot",
    firstName: "Cam",
    lastName: "Talbot",
    primaryNumber: 33.0,
    birthDate: "1987-07-05",
    currentAge: 35.0,
    birthCity: "Caledonia",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 4\"",
    weight: 201,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 9.0,
    GPI: -0.885717216370228,
    GOALS_GOTTEN: 40,
  },
  {
    "Unnamed: 0": 927,
    id: 8475683,
    fullName: "Sergei Bobrovsky",
    firstName: "Sergei",
    lastName: "Bobrovsky",
    primaryNumber: 72.0,
    birthDate: "1988-09-20",
    currentAge: 34.0,
    birthCity: "Novokuznetsk",
    birthCountry: "RUS",
    nationality: "RUS",
    height: "6' 2\"",
    weight: 182,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 13.0,
    GPI: 0.21681771140822903,
    GOALS_GOTTEN: 47,
  },
  {
    "Unnamed: 0": 882,
    id: 8475789,
    fullName: "Jack Campbell",
    firstName: "Jack",
    lastName: "Campbell",
    primaryNumber: 36.0,
    birthDate: "1992-01-09",
    currentAge: 31.0,
    birthCity: "Port Huron",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 3\"",
    weight: 207,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 22.0,
    GPI: -0.6088795564795575,
    GOALS_GOTTEN: 45,
  },
  {
    "Unnamed: 0": 904,
    id: 8475809,
    fullName: "Scott Wedgewood",
    firstName: "Scott",
    lastName: "Wedgewood",
    primaryNumber: 41.0,
    birthDate: "1992-08-14",
    currentAge: 30.0,
    birthCity: "Brampton",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 2\"",
    weight: 205,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 25.0,
    GPI: 1.8263967032967043,
    GOALS_GOTTEN: 18,
  },
  {
    "Unnamed: 0": 934,
    id: 8475831,
    fullName: "Philipp Grubauer",
    firstName: "Philipp",
    lastName: "Grubauer",
    primaryNumber: 31.0,
    birthDate: "1991-11-25",
    currentAge: 31.0,
    birthCity: "Rosenheim",
    birthCountry: "DEU",
    nationality: "DEU",
    height: "6' 1\"",
    weight: 188,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 55.0,
    GPI: -1.9355097018783383,
    GOALS_GOTTEN: 38,
  },
  {
    "Unnamed: 0": 878,
    id: 8475852,
    fullName: "Petr Mrazek",
    firstName: "Petr",
    lastName: "Mrazek",
    primaryNumber: 34.0,
    birthDate: "1992-02-14",
    currentAge: 31.0,
    birthCity: "Ostrava",
    birthCountry: "CZE",
    nationality: "CZE",
    height: "6' 2\"",
    weight: 188,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 16.0,
    GPI: 3.2415986821571003,
    GOALS_GOTTEN: 48,
  },
  {
    "Unnamed: 0": 894,
    id: 8475883,
    fullName: "Frederik Andersen",
    firstName: "Frederik",
    lastName: "Andersen",
    primaryNumber: 31.0,
    birthDate: "1989-10-02",
    currentAge: 33.0,
    birthCity: "Herning",
    birthCountry: "DNK",
    nationality: "DNK",
    height: "6' 4\"",
    weight: 238,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 12.0,
    GPI: -1.5993571551279855,
    GOALS_GOTTEN: 29,
  },
  {
    "Unnamed: 0": 955,
    id: 8476234,
    fullName: "Keith Kinkaid",
    firstName: "Keith",
    lastName: "Kinkaid",
    primaryNumber: 30.0,
    birthDate: "1989-07-04",
    currentAge: 33.0,
    birthCity: "Farmingville",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 2\"",
    weight: 195,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 21.0,
    GPI: -0.07172331800374718,
    GOALS_GOTTEN: 0,
  },
  {
    "Unnamed: 0": 915,
    id: 8476316,
    fullName: "Laurent Brossoit",
    firstName: "Laurent",
    lastName: "Brossoit",
    primaryNumber: 39.0,
    birthDate: "1993-03-23",
    currentAge: 30.0,
    birthCity: "Port Alberni",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 3\"",
    weight: 215,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 54.0,
    GPI: -2.0701455413469736,
    GOALS_GOTTEN: 5,
  },
  {
    "Unnamed: 0": 948,
    id: 8476341,
    fullName: "Anton Forsberg",
    firstName: "Anton",
    lastName: "Forsberg",
    primaryNumber: 31.0,
    birthDate: "1992-11-27",
    currentAge: 30.0,
    birthCity: "Härnösand",
    birthCountry: "SWE",
    nationality: "SWE",
    height: "6' 3\"",
    weight: 193,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "I",
    primaryPosition: "Goalie",
    currentTeamId: 9.0,
    GPI: -0.3446842541436457,
    GOALS_GOTTEN: 39,
  },
  {
    "Unnamed: 0": 923,
    id: 8476412,
    fullName: "Jordan Binnington",
    firstName: "Jordan",
    lastName: "Binnington",
    primaryNumber: 50.0,
    birthDate: "1993-07-11",
    currentAge: 29.0,
    birthCity: "Richmond Hill",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 2\"",
    weight: 172,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 19.0,
    GPI: -0.5009662764284464,
    GOALS_GOTTEN: 86,
  },
  {
    "Unnamed: 0": 929,
    id: 8476433,
    fullName: "Magnus Hellberg",
    firstName: "Magnus",
    lastName: "Hellberg",
    primaryNumber: 45.0,
    birthDate: "1991-04-04",
    currentAge: 31.0,
    birthCity: "Uppsala",
    birthCountry: "SWE",
    nationality: "SWE",
    height: "6' 6\"",
    weight: 220,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 17.0,
    GPI: -0.4009019339380453,
    GOALS_GOTTEN: 4,
  },
  {
    "Unnamed: 0": 928,
    id: 8476434,
    fullName: "John Gibson",
    firstName: "John",
    lastName: "Gibson",
    primaryNumber: 36.0,
    birthDate: "1993-07-14",
    currentAge: 29.0,
    birthCity: "Pittsburgh",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 2\"",
    weight: 210,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 24.0,
    GPI: 9.338952860892388,
    GOALS_GOTTEN: 82,
  },
  {
    "Unnamed: 0": 896,
    id: 8476883,
    fullName: "Andrei Vasilevskiy",
    firstName: "Andrei",
    lastName: "Vasilevskiy",
    primaryNumber: 88.0,
    birthDate: "1994-07-25",
    currentAge: 28.0,
    birthCity: "Tyumen",
    birthCountry: "RUS",
    nationality: "RUS",
    height: "6' 4\"",
    weight: 220,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 14.0,
    GPI: 0.6418704735376082,
    GOALS_GOTTEN: 72,
  },
  {
    "Unnamed: 0": 883,
    id: 8476899,
    fullName: "Matt Murray",
    firstName: "Matt",
    lastName: "Murray",
    primaryNumber: 30.0,
    birthDate: "1994-05-25",
    currentAge: 28.0,
    birthCity: "Thunder Bay",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 5\"",
    weight: 203,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 10.0,
    GPI: -0.9476241841291655,
    GOALS_GOTTEN: 20,
  },
  {
    "Unnamed: 0": 954,
    id: 8476914,
    fullName: "Joonas Korpisalo",
    firstName: "Joonas",
    lastName: "Korpisalo",
    primaryNumber: 70.0,
    birthDate: "1994-04-28",
    currentAge: 28.0,
    birthCity: "Pori",
    birthCountry: "FIN",
    nationality: "FIN",
    height: "6' 3\"",
    weight: 193,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 26.0,
    GPI: 1.875986873920553,
    GOALS_GOTTEN: 59,
  },
  {
    "Unnamed: 0": 963,
    id: 8476932,
    fullName: "Anthony Stolarz",
    firstName: "Anthony",
    lastName: "Stolarz",
    primaryNumber: 41.0,
    birthDate: "1994-01-20",
    currentAge: 29.0,
    birthCity: "Edison",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 6\"",
    weight: 243,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "I",
    primaryPosition: "Goalie",
    currentTeamId: 24.0,
    GPI: 3.0885587356124375,
    GOALS_GOTTEN: 13,
  },
  {
    "Unnamed: 0": 901,
    id: 8476945,
    fullName: "Connor Hellebuyck",
    firstName: "Connor",
    lastName: "Hellebuyck",
    primaryNumber: 37.0,
    birthDate: "1993-05-19",
    currentAge: 29.0,
    birthCity: "Commerce",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 4\"",
    weight: 216,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 52.0,
    GPI: -5.382132219902575,
    GOALS_GOTTEN: 57,
  },
  {
    "Unnamed: 0": 877,
    id: 8476999,
    fullName: "Linus Ullmark",
    firstName: "Linus",
    lastName: "Ullmark",
    primaryNumber: 35.0,
    birthDate: "1993-07-31",
    currentAge: 29.0,
    birthCity: "Lugnvik",
    birthCountry: "SWE",
    nationality: "SWE",
    height: "6' 5\"",
    weight: 212,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 6.0,
    GPI: -3.8167600620796662,
    GOALS_GOTTEN: 36,
  },
  {
    "Unnamed: 0": 920,
    id: 8477180,
    fullName: "Aaron Dell",
    firstName: "Aaron",
    lastName: "Dell",
    primaryNumber: 30.0,
    birthDate: "1989-05-04",
    currentAge: 33.0,
    birthCity: "Airdrie",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 0\"",
    weight: 213,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "N",
    primaryPosition: "Goalie",
    currentTeamId: 28.0,
    GPI: 0.2717165273750639,
    GOALS_GOTTEN: 2,
  },
  {
    "Unnamed: 0": 889,
    id: 8477293,
    fullName: "Antti Raanta",
    firstName: "Antti",
    lastName: "Raanta",
    primaryNumber: 32.0,
    birthDate: "1989-05-12",
    currentAge: 33.0,
    birthCity: "Rauma",
    birthCountry: "FIN",
    nationality: "FIN",
    height: "6' 0\"",
    weight: 195,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 12.0,
    GPI: -3.490773484458181,
    GOALS_GOTTEN: 22,
  },
  {
    "Unnamed: 0": 879,
    id: 8477361,
    fullName: "Cal Petersen",
    firstName: "Cal",
    lastName: "Petersen",
    primaryNumber: 40.0,
    birthDate: "1994-10-19",
    currentAge: 28.0,
    birthCity: "Waterloo",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 2\"",
    weight: 185,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "R",
    rosterStatus: "N",
    primaryPosition: "Goalie",
    currentTeamId: 26.0,
    GPI: 0.756502774922919,
    GOALS_GOTTEN: 13,
  },
  {
    "Unnamed: 0": 897,
    id: 8477424,
    fullName: "Juuse Saros",
    firstName: "Juuse",
    lastName: "Saros",
    primaryNumber: 74.0,
    birthDate: "1995-04-19",
    currentAge: 27.0,
    birthCity: "Forssa",
    birthCountry: "FIN",
    nationality: "FIN",
    height: "5' 11\"",
    weight: 180,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 18.0,
    GPI: -1.3865939752742449,
    GOALS_GOTTEN: 74,
  },
  {
    "Unnamed: 0": 890,
    id: 8477465,
    fullName: "Tristan Jarry",
    firstName: "Tristan",
    lastName: "Jarry",
    primaryNumber: 35.0,
    birthDate: "1995-04-29",
    currentAge: 27.0,
    birthCity: "Surrey",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 2\"",
    weight: 194,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 5.0,
    GPI: -0.9505768406498429,
    GOALS_GOTTEN: 44,
  },
  {
    "Unnamed: 0": 956,
    id: 8477480,
    fullName: "Eric Comrie",
    firstName: "Eric",
    lastName: "Comrie",
    primaryNumber: 31.0,
    birthDate: "1995-07-06",
    currentAge: 27.0,
    birthCity: "Edmonton",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 1\"",
    weight: 183,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 7.0,
    GPI: -1.0569371821305822,
    GOALS_GOTTEN: 26,
  },
  {
    "Unnamed: 0": 967,
    id: 8477484,
    fullName: "Spencer Martin",
    firstName: "Spencer",
    lastName: "Martin",
    primaryNumber: 30.0,
    birthDate: "1995-06-08",
    currentAge: 27.0,
    birthCity: "Oakville",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 3\"",
    weight: 191,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "N",
    primaryPosition: "Goalie",
    currentTeamId: 23.0,
    GPI: 2.9101407111882054,
    GOALS_GOTTEN: 50,
  },
  {
    "Unnamed: 0": 869,
    id: 8477831,
    fullName: "Pheonix Copley",
    firstName: "Pheonix",
    lastName: "Copley",
    primaryNumber: 29.0,
    birthDate: "1992-01-18",
    currentAge: 31.0,
    birthCity: "North Pole",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 4\"",
    weight: 200,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 26.0,
    GPI: 1.1346958441110537,
    GOALS_GOTTEN: 28,
  },
  {
    "Unnamed: 0": 921,
    id: 8477967,
    fullName: "Thatcher Demko",
    firstName: "Thatcher",
    lastName: "Demko",
    primaryNumber: 35.0,
    birthDate: "1995-12-08",
    currentAge: 27.0,
    birthCity: "San Diego",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 4\"",
    weight: 192,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 23.0,
    GPI: -2.5584015146299492,
    GOALS_GOTTEN: 25,
  },
  {
    "Unnamed: 0": 952,
    id: 8477968,
    fullName: "Alex Nedeljkovic",
    firstName: "Alex",
    lastName: "Nedeljkovic",
    primaryNumber: 39.0,
    birthDate: "1996-01-07",
    currentAge: 27.0,
    birthCity: "Parma",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 0\"",
    weight: 208,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 17.0,
    GPI: 2.1212550967963004,
    GOALS_GOTTEN: 10,
  },
  {
    "Unnamed: 0": 925,
    id: 8477970,
    fullName: "Vitek Vanecek",
    firstName: "Vitek",
    lastName: "Vanecek",
    primaryNumber: 41.0,
    birthDate: "1996-01-09",
    currentAge: 27.0,
    birthCity: "Havlickuv Brod",
    birthCountry: "CZE",
    nationality: "CZE",
    height: "6' 2\"",
    weight: 184,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 1.0,
    GPI: -3.018133079716412,
    GOALS_GOTTEN: 46,
  },
  {
    "Unnamed: 0": 911,
    id: 8478007,
    fullName: "Elvis Merzlikins",
    firstName: "Elvis",
    lastName: "Merzlikins",
    primaryNumber: 90.0,
    birthDate: "1994-04-13",
    currentAge: 28.0,
    birthCity: "Riga",
    birthCountry: "LVA",
    nationality: "LVA",
    height: "6' 3\"",
    weight: 183,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 29.0,
    GPI: 4.1762103950103935,
    GOALS_GOTTEN: 51,
  },
  {
    "Unnamed: 0": 939,
    id: 8478009,
    fullName: "Ilya Sorokin",
    firstName: "Ilya",
    lastName: "Sorokin",
    primaryNumber: 30.0,
    birthDate: "1995-08-04",
    currentAge: 27.0,
    birthCity: "Mezhdurechensk",
    birthCountry: "RUS",
    nationality: "RUS",
    height: "6' 3\"",
    weight: 195,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 2.0,
    GPI: -1.8912501214855943,
    GOALS_GOTTEN: 54,
  },
  {
    "Unnamed: 0": 950,
    id: 8478024,
    fullName: "Ville Husso",
    firstName: "Ville",
    lastName: "Husso",
    primaryNumber: 35.0,
    birthDate: "1995-02-06",
    currentAge: 28.0,
    birthCity: "Helsinki",
    birthCountry: "FIN",
    nationality: "FIN",
    height: "6' 3\"",
    weight: 200,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 17.0,
    GPI: 1.1047372453421556,
    GOALS_GOTTEN: 75,
  },
  {
    "Unnamed: 0": 884,
    id: 8478039,
    fullName: "Kaapo Kahkonen",
    firstName: "Kaapo",
    lastName: "Kahkonen",
    primaryNumber: 36.0,
    birthDate: "1996-08-16",
    currentAge: 26.0,
    birthCity: "Helsinki",
    birthCountry: "FIN",
    nationality: "FIN",
    height: "6' 2\"",
    weight: 217,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 28.0,
    GPI: -0.7790269896193784,
    GOALS_GOTTEN: 49,
  },
  {
    "Unnamed: 0": 900,
    id: 8478048,
    fullName: "Igor Shesterkin",
    firstName: "Igor",
    lastName: "Shesterkin",
    primaryNumber: 31.0,
    birthDate: "1995-12-30",
    currentAge: 27.0,
    birthCity: "Moscow",
    birthCountry: "RUS",
    nationality: "RUS",
    height: "6' 1\"",
    weight: 189,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 3.0,
    GPI: -1.4726269819193316,
    GOALS_GOTTEN: 70,
  },
  {
    "Unnamed: 0": 871,
    id: 8478406,
    fullName: "Mackenzie Blackwood",
    firstName: "Mackenzie",
    lastName: "Blackwood",
    primaryNumber: 29.0,
    birthDate: "1996-12-09",
    currentAge: 26.0,
    birthCity: "Thunder Bay",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 4\"",
    weight: 225,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 1.0,
    GPI: -0.09656725025746571,
    GOALS_GOTTEN: 19,
  },
  {
    "Unnamed: 0": 943,
    id: 8478433,
    fullName: "Felix Sandstrom",
    firstName: "Felix",
    lastName: "Sandstrom",
    primaryNumber: 32.0,
    birthDate: "1997-01-12",
    currentAge: 26.0,
    birthCity: "Gävle",
    birthCountry: "SWE",
    nationality: "SWE",
    height: "6' 2\"",
    weight: 191,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 4.0,
    GPI: 1.1932156271418775,
    GOALS_GOTTEN: 16,
  },
  {
    "Unnamed: 0": 932,
    id: 8478435,
    fullName: "Dan Vladar",
    firstName: "Dan",
    lastName: "Vladar",
    primaryNumber: 80.0,
    birthDate: "1997-08-20",
    currentAge: 25.0,
    birthCity: "Prague",
    birthCountry: "CZE",
    nationality: "CZE",
    height: "6' 5\"",
    weight: 209,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 20.0,
    GPI: -1.0866426184323874,
    GOALS_GOTTEN: 29,
  },
  {
    "Unnamed: 0": 949,
    id: 8478470,
    fullName: "Sam Montembeault",
    firstName: "Sam",
    lastName: "Montembeault",
    primaryNumber: 35.0,
    birthDate: "1996-10-30",
    currentAge: 26.0,
    birthCity: "Becancour",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 3\"",
    weight: 214,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 8.0,
    GPI: 0.891482987551866,
    GOALS_GOTTEN: 29,
  },
  {
    "Unnamed: 0": 942,
    id: 8478492,
    fullName: "Ilya Samsonov",
    firstName: "Ilya",
    lastName: "Samsonov",
    primaryNumber: 35.0,
    birthDate: "1997-02-22",
    currentAge: 26.0,
    birthCity: "Magnitogorsk",
    birthCountry: "RUS",
    nationality: "RUS",
    height: "6' 3\"",
    weight: 214,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 10.0,
    GPI: -2.473525823701914,
    GOALS_GOTTEN: 39,
  },
  {
    "Unnamed: 0": 910,
    id: 8478499,
    fullName: "Adin Hill",
    firstName: "Adin",
    lastName: "Hill",
    primaryNumber: 33.0,
    birthDate: "1996-05-11",
    currentAge: 26.0,
    birthCity: "Comox",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 4\"",
    weight: 215,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 54.0,
    GPI: -1.2399358568479002,
    GOALS_GOTTEN: 34,
  },
  {
    "Unnamed: 0": 870,
    id: 8478872,
    fullName: "Karel Vejmelka",
    firstName: "Karel",
    lastName: "Vejmelka",
    primaryNumber: 70.0,
    birthDate: "1996-05-25",
    currentAge: 26.0,
    birthCity: "Trebic",
    birthCountry: "CZE",
    nationality: "CZE",
    height: "6' 4\"",
    weight: 224,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "R",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 53.0,
    GPI: 3.8209037502180383,
    GOALS_GOTTEN: 57,
  },
  {
    "Unnamed: 0": 936,
    id: 8478902,
    fullName: "Erik Kallgren",
    firstName: "Erik",
    lastName: "Kallgren",
    primaryNumber: 50.0,
    birthDate: "1996-10-14",
    currentAge: 26.0,
    birthCity: "Stockholm",
    birthCountry: "SWE",
    nationality: "SWE",
    height: "6' 3\"",
    weight: 200,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "N",
    primaryPosition: "Goalie",
    currentTeamId: 10.0,
    GPI: 1.3610169317598773,
    GOALS_GOTTEN: 15,
  },
  {
    "Unnamed: 0": 872,
    id: 8478916,
    fullName: "Joey Daccord",
    firstName: "Joey",
    lastName: "Daccord",
    primaryNumber: 35.0,
    birthDate: "1996-08-19",
    currentAge: 26.0,
    birthCity: "Boston",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 2\"",
    weight: 201,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "N",
    primaryPosition: "Goalie",
    currentTeamId: 55.0,
    GPI: 0.513505385139741,
    GOALS_GOTTEN: 0,
  },
  {
    "Unnamed: 0": 931,
    id: 8478971,
    fullName: "Connor Ingram",
    firstName: "Connor",
    lastName: "Ingram",
    primaryNumber: 39.0,
    birthDate: "1997-03-31",
    currentAge: 25.0,
    birthCity: "Saskatoon",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 2\"",
    weight: 196,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 53.0,
    GPI: 1.722235683502845,
    GOALS_GOTTEN: 27,
  },
  {
    "Unnamed: 0": 899,
    id: 8479193,
    fullName: "Casey DeSmith",
    firstName: "Casey",
    lastName: "DeSmith",
    primaryNumber: 1.0,
    birthDate: "1991-08-13",
    currentAge: 31.0,
    birthCity: "Rochester",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 0\"",
    weight: 181,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 5.0,
    GPI: -2.397284356093346,
    GOALS_GOTTEN: 36,
  },
  {
    "Unnamed: 0": 965,
    id: 8479292,
    fullName: "Charlie Lindgren",
    firstName: "Charlie",
    lastName: "Lindgren",
    primaryNumber: 79.0,
    birthDate: "1993-12-18",
    currentAge: 29.0,
    birthCity: "Lakeville",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 2\"",
    weight: 179,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "R",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 15.0,
    GPI: -0.13326308539944876,
    GOALS_GOTTEN: 30,
  },
  {
    "Unnamed: 0": 926,
    id: 8479312,
    fullName: "Alex Lyon",
    firstName: "Alex",
    lastName: "Lyon",
    primaryNumber: 34.0,
    birthDate: "1992-12-09",
    currentAge: 30.0,
    birthCity: "Baudette",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 1\"",
    weight: 201,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 13.0,
    GPI: 1.730020926654129,
    GOALS_GOTTEN: 9,
  },
  {
    "Unnamed: 0": 941,
    id: 8479361,
    fullName: "Joseph Woll",
    firstName: "Joseph",
    lastName: "Woll",
    primaryNumber: 60.0,
    birthDate: "1998-07-12",
    currentAge: 24.0,
    birthCity: "Dardenne Prairie",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 3\"",
    weight: 203,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 10.0,
    GPI: 0.37791953631094444,
    GOALS_GOTTEN: 5,
  },
  {
    "Unnamed: 0": 885,
    id: 8479380,
    fullName: "Dylan Wells",
    firstName: "Dylan",
    lastName: "Wells",
    primaryNumber: -1,
    birthDate: "1998-01-03",
    currentAge: 25.0,
    birthCity: "St. Catharines",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 2\"",
    weight: 190,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "N",
    primaryPosition: "Goalie",
    currentTeamId: 25.0,
    GPI: 0.12821073071027084,
    GOALS_GOTTEN: 0,
  },
  {
    "Unnamed: 0": 938,
    id: 8479394,
    fullName: "Carter Hart",
    firstName: "Carter",
    lastName: "Hart",
    primaryNumber: 79.0,
    birthDate: "1998-08-13",
    currentAge: 24.0,
    birthCity: "Sherwood Park",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 2\"",
    weight: 181,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 4.0,
    GPI: 5.2908303515489035,
    GOALS_GOTTEN: 72,
  },
  {
    "Unnamed: 0": 935,
    id: 8479406,
    fullName: "Filip Gustavsson",
    firstName: "Filip",
    lastName: "Gustavsson",
    primaryNumber: 32.0,
    birthDate: "1998-06-07",
    currentAge: 24.0,
    birthCity: "Skellefteå",
    birthCountry: "SWE",
    nationality: "SWE",
    height: "6' 2\"",
    weight: 184,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 30.0,
    GPI: -1.2038022008253098,
    GOALS_GOTTEN: 36,
  },
  {
    "Unnamed: 0": 961,
    id: 8479496,
    fullName: "David Rittich",
    firstName: "David",
    lastName: "Rittich",
    primaryNumber: 33.0,
    birthDate: "1992-08-19",
    currentAge: 30.0,
    birthCity: "Jihlava",
    birthCountry: "CZE",
    nationality: "CZE",
    height: "6' 4\"",
    weight: 205,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 52.0,
    GPI: -1.8489390946502056,
    GOALS_GOTTEN: 17,
  },
  {
    "Unnamed: 0": 946,
    id: 8479973,
    fullName: "Stuart Skinner",
    firstName: "Stuart",
    lastName: "Skinner",
    primaryNumber: 74.0,
    birthDate: "1998-11-01",
    currentAge: 24.0,
    birthCity: "Edmonton",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 4\"",
    weight: 206,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 22.0,
    GPI: -2.714790017331021,
    GOALS_GOTTEN: 56,
  },
  {
    "Unnamed: 0": 875,
    id: 8479979,
    fullName: "Jake Oettinger",
    firstName: "Jake",
    lastName: "Oettinger",
    primaryNumber: 29.0,
    birthDate: "1998-12-18",
    currentAge: 24.0,
    birthCity: "Lakeville",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 5\"",
    weight: 220,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 25.0,
    GPI: 1.6649612064482593,
    GOALS_GOTTEN: 70,
  },
  {
    "Unnamed: 0": 933,
    id: 8480045,
    fullName: "Ukko-Pekka Luukkonen",
    firstName: "Ukko-Pekka",
    lastName: "Luukkonen",
    primaryNumber: 1.0,
    birthDate: "1999-03-09",
    currentAge: 24.0,
    birthCity: "Espoo",
    birthCountry: "FIN",
    nationality: "FIN",
    height: "6' 5\"",
    weight: 217,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 7.0,
    GPI: 2.039939377162632,
    GOALS_GOTTEN: 54,
  },
  {
    "Unnamed: 0": 902,
    id: 8480051,
    fullName: "Cayden Primeau",
    firstName: "Cayden",
    lastName: "Primeau",
    primaryNumber: 30.0,
    birthDate: "1999-08-11",
    currentAge: 23.0,
    birthCity: "Farmington Hills",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 2\"",
    weight: 199,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "N",
    primaryPosition: "Goalie",
    currentTeamId: 8.0,
    GPI: 0.12831074774314427,
    GOALS_GOTTEN: 1,
  },
  {
    "Unnamed: 0": 914,
    id: 8480193,
    fullName: "Daniil Tarasov",
    firstName: "Daniil",
    lastName: "Tarasov",
    primaryNumber: 40.0,
    birthDate: "1999-03-27",
    currentAge: 24.0,
    birthCity: "Novokuznetsk",
    birthCountry: "RUS",
    nationality: "RUS",
    height: "6' 5\"",
    weight: 196,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "N",
    primaryPosition: "Goalie",
    currentTeamId: 29.0,
    GPI: 1.5370744130248508,
    GOALS_GOTTEN: 15,
  },
  {
    "Unnamed: 0": 908,
    id: 8480199,
    fullName: "Eetu Makiniemi",
    firstName: "Eetu",
    lastName: "Makiniemi",
    primaryNumber: 32.0,
    birthDate: "1999-04-19",
    currentAge: 23.0,
    birthCity: "Vantaa",
    birthCountry: "FIN",
    nationality: "FIN",
    height: "6' 2\"",
    weight: 184,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "N",
    primaryPosition: "Goalie",
    currentTeamId: 28.0,
    GPI: 0.36995569943772355,
    GOALS_GOTTEN: 2,
  },
  {
    "Unnamed: 0": 887,
    id: 8480280,
    fullName: "Jeremy Swayman",
    firstName: "Jeremy",
    lastName: "Swayman",
    primaryNumber: 1.0,
    birthDate: "1998-11-24",
    currentAge: 24.0,
    birthCity: "Anchorage",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 2\"",
    weight: 194,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 6.0,
    GPI: -2.339706410036089,
    GOALS_GOTTEN: 20,
  },
  {
    "Unnamed: 0": 964,
    id: 8480313,
    fullName: "Logan Thompson",
    firstName: "Logan",
    lastName: "Thompson",
    primaryNumber: 36.0,
    birthDate: "1997-02-25",
    currentAge: 26.0,
    birthCity: "Calgary",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 4\"",
    weight: 205,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "R",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 54.0,
    GPI: -5.323211567099564,
    GOALS_GOTTEN: 47,
  },
  {
    "Unnamed: 0": 880,
    id: 8480382,
    fullName: "Alexandar Georgiev",
    firstName: "Alexandar",
    lastName: "Georgiev",
    primaryNumber: 40.0,
    birthDate: "1996-02-10",
    currentAge: 27.0,
    birthCity: "Ruse",
    birthCountry: "BGR",
    nationality: "RUS",
    height: "6' 1\"",
    weight: 178,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 21.0,
    GPI: -2.672248160999306,
    GOALS_GOTTEN: 56,
  },
  {
    "Unnamed: 0": 892,
    id: 8480420,
    fullName: "Collin Delia",
    firstName: "Collin",
    lastName: "Delia",
    primaryNumber: 60.0,
    birthDate: "1994-06-20",
    currentAge: 28.0,
    birthCity: "Rancho Cucamonga",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 2\"",
    weight: 208,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 23.0,
    GPI: 0.5107177056500083,
    GOALS_GOTTEN: 18,
  },
  {
    "Unnamed: 0": 919,
    id: 8480843,
    fullName: "Lukas Dostal",
    firstName: "Lukas",
    lastName: "Dostal",
    primaryNumber: 1.0,
    birthDate: "2000-06-22",
    currentAge: 22.0,
    birthCity: "Brno",
    birthCountry: "CZE",
    nationality: "CZE",
    height: "6' 2\"",
    weight: 174,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 24.0,
    GPI: 1.060641892355159,
    GOALS_GOTTEN: 15,
  },
  {
    "Unnamed: 0": 966,
    id: 8480867,
    fullName: "Kevin Mandolese",
    firstName: "Kevin",
    lastName: "Mandolese",
    primaryNumber: 70.0,
    birthDate: "2000-08-22",
    currentAge: 22.0,
    birthCity: "Blainville",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 4\"",
    weight: 180,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "N",
    primaryPosition: "Goalie",
    currentTeamId: 9.0,
    GPI: -0.35155486620078424,
    GOALS_GOTTEN: 0,
  },
  {
    "Unnamed: 0": 873,
    id: 8480925,
    fullName: "Pavel Francouz",
    firstName: "Pavel",
    lastName: "Francouz",
    primaryNumber: 39.0,
    birthDate: "1990-06-03",
    currentAge: 32.0,
    birthCity: "Plzen",
    birthCountry: "CZE",
    nationality: "CZE",
    height: "6' 0\"",
    weight: 179,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "R",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 21.0,
    GPI: -1.2308874871443258,
    GOALS_GOTTEN: 17,
  },
  {
    "Unnamed: 0": 912,
    id: 8480947,
    fullName: "Kevin Lankinen",
    firstName: "Kevin",
    lastName: "Lankinen",
    primaryNumber: 32.0,
    birthDate: "1995-04-28",
    currentAge: 27.0,
    birthCity: "Helsinki",
    birthCountry: "FIN",
    nationality: "FIN",
    height: "6' 2\"",
    weight: 190,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 18.0,
    GPI: -0.08077005310947438,
    GOALS_GOTTEN: 12,
  },
  {
    "Unnamed: 0": 947,
    id: 8481020,
    fullName: "Justus Annunen",
    firstName: "Justus",
    lastName: "Annunen",
    primaryNumber: 60.0,
    birthDate: "2000-03-11",
    currentAge: 23.0,
    birthCity: "Kempele",
    birthCountry: "FIN",
    nationality: "FIN",
    height: "6' 4\"",
    weight: 210,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "N",
    primaryPosition: "Goalie",
    currentTeamId: 21.0,
    GPI: 0.2699440750213129,
    GOALS_GOTTEN: 6,
  },
  {
    "Unnamed: 0": 917,
    id: 8481033,
    fullName: "Akira Schmid",
    firstName: "Akira",
    lastName: "Schmid",
    primaryNumber: 40.0,
    birthDate: "2000-05-12",
    currentAge: 22.0,
    birthCity: "Bern",
    birthCountry: "CHE",
    nationality: "CHE",
    height: "6' 5\"",
    weight: 205,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "N",
    primaryPosition: "Goalie",
    currentTeamId: 1.0,
    GPI: -1.0201968557758034,
    GOALS_GOTTEN: 13,
  },
  {
    "Unnamed: 0": 895,
    id: 8481035,
    fullName: "Samuel Ersson",
    firstName: "Samuel",
    lastName: "Ersson",
    primaryNumber: 33.0,
    birthDate: "1999-10-20",
    currentAge: 23.0,
    birthCity: "Falun",
    birthCountry: "SWE",
    nationality: "SWE",
    height: "6' 2\"",
    weight: 176,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "N",
    primaryPosition: "Goalie",
    currentTeamId: 4.0,
    GPI: 0.634417313943541,
    GOALS_GOTTEN: 2,
  },
  {
    "Unnamed: 0": 913,
    id: 8481519,
    fullName: "Spencer Knight",
    firstName: "Spencer",
    lastName: "Knight",
    primaryNumber: 30.0,
    birthDate: "2001-04-19",
    currentAge: 21.0,
    birthCity: "Darien",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 3\"",
    weight: 192,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: false,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 13.0,
    GPI: 1.6911679173838214,
    GOALS_GOTTEN: 23,
  },
  {
    "Unnamed: 0": 881,
    id: 8481544,
    fullName: "Mads Sogaard",
    firstName: "Mads",
    lastName: "Sogaard",
    primaryNumber: 40.0,
    birthDate: "2000-12-13",
    currentAge: 22.0,
    birthCity: "Aalborg",
    birthCountry: "DNK",
    nationality: "DNK",
    height: "6' 7\"",
    weight: 196,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 9.0,
    GPI: 0.38545447098976093,
    GOALS_GOTTEN: 10,
  },
  {
    "Unnamed: 0": 891,
    id: 8481611,
    fullName: "Pyotr Kochetkov",
    firstName: "Pyotr",
    lastName: "Kochetkov",
    primaryNumber: 52.0,
    birthDate: "1999-06-25",
    currentAge: 23.0,
    birthCity: "Penza",
    birthCountry: "RUS",
    nationality: "RUS",
    height: "6' 3\"",
    weight: 205,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 12.0,
    GPI: -1.7838403156630633,
    GOALS_GOTTEN: 21,
  },
  {
    "Unnamed: 0": 907,
    id: 8481668,
    fullName: "Arturs Silovs",
    firstName: "Arturs",
    lastName: "Silovs",
    primaryNumber: 31.0,
    birthDate: "2001-03-22",
    currentAge: 22.0,
    birthCity: "Riga",
    birthCountry: "LVA",
    nationality: "LVA",
    height: "6' 4\"",
    weight: 203,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "N",
    primaryPosition: "Goalie",
    currentTeamId: 23.0,
    GPI: 0.050873161576522774,
    GOALS_GOTTEN: 9,
  },
  {
    "Unnamed: 0": 905,
    id: 8482137,
    fullName: "Yaroslav Askarov",
    firstName: "Yaroslav",
    lastName: "Askarov",
    primaryNumber: 30.0,
    birthDate: "2002-06-16",
    currentAge: 20.0,
    birthCity: "Omsk",
    birthCountry: "RUS",
    nationality: "RUS",
    height: "6' 3\"",
    weight: 178,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "R",
    rosterStatus: "N",
    primaryPosition: "Goalie",
    currentTeamId: 18.0,
    GPI: -0.19407661895023853,
    GOALS_GOTTEN: 0,
  },
  {
    "Unnamed: 0": 960,
    id: 8482821,
    fullName: "Arvid Soderblom",
    firstName: "Arvid",
    lastName: "Soderblom",
    primaryNumber: 40.0,
    birthDate: "1999-08-19",
    currentAge: 23.0,
    birthCity: "Göteborg",
    birthCountry: "SWE",
    nationality: "SWE",
    height: "6' 3\"",
    weight: 180,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "N",
    primaryPosition: "Goalie",
    currentTeamId: 16.0,
    GPI: 2.926791758241759,
    GOALS_GOTTEN: 32,
  },
  {
    "Unnamed: 0": 874,
    id: 8483530,
    fullName: "Jaxson Stauber",
    firstName: "Jaxson",
    lastName: "Stauber",
    primaryNumber: 30.0,
    birthDate: "1999-04-27",
    currentAge: 23.0,
    birthCity: "Wayzata",
    birthCountry: "USA",
    nationality: "USA",
    height: "6' 3\"",
    weight: 174,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "N",
    primaryPosition: "Goalie",
    currentTeamId: 16.0,
    GPI: -1.2620424935952177,
    GOALS_GOTTEN: 6,
  },
  {
    "Unnamed: 0": 930,
    id: 8483575,
    fullName: "Matt Murray",
    firstName: "Matt",
    lastName: "Murray",
    primaryNumber: 32.0,
    birthDate: "1998-02-02",
    currentAge: 25.0,
    birthCity: "St. Albert",
    birthCountry: "CAN",
    nationality: "CAN",
    height: "6' 1\"",
    weight: 200,
    active: true,
    alternateCaptain: false,
    captain: false,
    rookie: true,
    shootsCatches: "L",
    rosterStatus: "Y",
    primaryPosition: "Goalie",
    currentTeamId: 25.0,
    GPI: -0.2040917206132879,
    GOALS_GOTTEN: 0,
  },
];

const series = data.map((d) => ({
  name: d.fullName,
  data: [[d.GOALS_GOTTEN, parseFloat(d.GPI).toFixed(2)]],
}));

const options = {
  chart: {
    height: 100,
    type: "scatter",
  },
  legend: {
    show: false,
  },
  xaxis: {
    type: "numeric",
    title: {
      text: "Goals Allowed",
    },
  },
  yaxis: {
    title: {
      text: "Goal Pressure Index",
    },
  },
};

const GoaliePressureIndex = () => {
  return (
    <>
      <Card bordered={false} className="criclebox h-full">
        <div className="linechart">
          <div>
            <Title level={5}>Live Winning Probability</Title>
            <Paragraph className="lastweek">
              Last updated: <span className="bnb2">March 27th 2023</span>
            </Paragraph>
          </div>
        </div>
        <div id="chart">
          <ReactApexChart options={options} series={series} type="scatter" />
        </div>
      </Card>
    </>
  );
};
export default GoaliePressureIndex;
