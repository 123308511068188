import { Typography, Button, Row, Col, Grid, Space } from "antd";
import mockup from "../assets/images/Mockup.png";
import InfoCard from "../components/elements/InfoCard";
import { useRef } from "react";
import FeatureCard from "../components/elements/FeatureCard";

const { Title, Paragraph } = Typography;

const { useBreakpoint } = Grid;

const cardsList = [
  {
    data: "2M",
    text: "Training Events",
    delay: "0",
  },
  {
    data: "68%",
    text: "Pre-Game Accuracy",
    delay: "200",
  },
  {
    data: "50%",
    text: "Overtime Accuracy",
    delay: "400",
  },
  {
    data: "100%",
    text: "Calibrated",
    delay: "600",
  },
];

const plusSymbol = [
  <svg
    width="64"
    height="64"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fill="none"
      className="fill-blue"
      d="M13.388,9.624h-3.011v-3.01c0-0.208-0.168-0.377-0.376-0.377S9.624,6.405,9.624,6.613v3.01H6.613c-0.208,0-0.376,0.168-0.376,0.376s0.168,0.376,0.376,0.376h3.011v3.01c0,0.208,0.168,0.378,0.376,0.378s0.376-0.17,0.376-0.378v-3.01h3.011c0.207,0,0.377-0.168,0.377-0.376S13.595,9.624,13.388,9.624z M10,1.344c-4.781,0-8.656,3.875-8.656,8.656c0,4.781,3.875,8.656,8.656,8.656c4.781,0,8.656-3.875,8.656-8.656C18.656,5.219,14.781,1.344,10,1.344z M10,17.903c-4.365,0-7.904-3.538-7.904-7.903S5.635,2.096,10,2.096S17.903,5.635,17.903,10S14.365,17.903,10,17.903z"
    ></path>
  </svg>,
];

const pressureSymbol = [
  <svg
    width="64"
    height="64"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fill="none"
      className="fill-blue"
      d="M4.68,13.716v-0.169H4.554C4.592,13.605,4.639,13.658,4.68,13.716z M11.931,6.465
	c-0.307-0.087-0.623,0.106-0.706,0.432l-1.389,5.484c-0.901,0.084-1.609,0.833-1.609,1.757c0,0.979,0.793,1.773,1.773,1.773
	c0.979,0,1.773-0.794,1.773-1.773c0-0.624-0.324-1.171-0.812-1.486l1.377-5.439C12.422,6.887,12.239,6.552,11.931,6.465z
	M10.591,14.729H9.408v-1.182h1.183V14.729z M15.32,13.716c0.04-0.058,0.087-0.11,0.126-0.169H15.32V13.716z M10,3.497
	c-3.592,0-6.503,2.911-6.503,6.503H4.68c0-2.938,2.382-5.32,5.32-5.32s5.32,2.382,5.32,5.32h1.182
	C16.502,6.408,13.591,3.497,10,3.497z M10,0.542c-5.224,0-9.458,4.234-9.458,9.458c0,5.224,4.234,9.458,9.458,9.458
	c5.224,0,9.458-4.234,9.458-9.458C19.458,4.776,15.224,0.542,10,0.542z M15.32,16.335v0.167h-0.212
	c-1.407,1.107-3.179,1.773-5.108,1.773c-1.93,0-3.701-0.666-5.108-1.773H4.68v-0.167C2.874,14.816,1.724,12.543,1.724,10
	c0-4.571,3.706-8.276,8.276-8.276c4.57,0,8.275,3.706,8.275,8.276C18.275,12.543,17.126,14.816,15.32,16.335z"
    ></path>
  </svg>,
];

const featureData = [
  {
    icon: plusSymbol,
    name: "Added Goal Value (Available Soon)",
    text: "The Added Goal value statistic is a measure of a player's contribution to their team's success in winning a game, based on the impact of their goals. It is calculated based on the average change in win probability given a player scores a goal. This statistic enables coaches and fans to uncover and benefit from key players.",
    path: "/AGV",
  },
  {
    icon: pressureSymbol,
    name: "Goalie Pressure Index (Available Soon)",
    text: "The Goalie Pressure Index is an index that measures a goalie's ability to perform well under pressure. It is calculated based on how much the probability of their team losing a game changes when the goalie allows a goal. With the help of this indicator, coaches can choose the best suited goalie while fans can adjust their expectation of the game.",
    path: "/GPI",
  },
];

const LandingPage = () => {
  const ref = useRef(null);

  const screens = useBreakpoint();

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  return (
    <>
      <div
        data-aos="fade-left"
        data-aos-duration="2000"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "65vh",
          marginLeft: "12vw",
          marginRight: "12vw",
          textAlign: "center",
        }}
      >
        <Title
          level={1}
          strong
          style={{
            fontSize: Object.entries(screens)
              .filter((screen) => !!screen[1])
              .map((screen) => screen[0])
              .includes("md")
              ? "40pt"
              : "27pt",
            fontFamily: "'Montserrat', sans-serif",
          }}
          className="gradient-title"
        >
          Predicting NHL <br />
          Outcomes In Real-Time
        </Title>
        <Paragraph
          style={{
            justifyItems: "center",
            fontSize: Object.entries(screens)
              .filter((screen) => !!screen[1])
              .map((screen) => screen[0])
              .includes("md")
              ? "13pt"
              : "11pt",
            marginBottom: "5vh",
          }}
        >
          Get accurate, up-to-date predictions and statistics for NHL games and
          players based on live data. Our powerful algorithms analyze the latest
          events in all games to give you an edge in predicting the outcomes of
          your favorite teams' games. Gain even more insights by following
          unique performance indecies like the Player Pressure Index (PPI) and
          the Goalie Pressure Index (GPI).
        </Paragraph>
        <Space
          direction={
            Object.entries(screens)
              .filter((screen) => !!screen[1])
              .map((screen) => screen[0])
              .includes("md")
              ? "horizontal"
              : "vertical"
          }
        >
          <Button type="primary" onClick={() => handleClick()}>
            Learn More
          </Button>
          <a href="https://www.buymeacoffee.com/qpdv5dr2gtv">
            <Button type="primary" ghost>
              Buy Me A Coffee ☕
            </Button>
          </a>
        </Space>
      </div>
      <div
        data-aos="fade-right"
        data-aos-duration="2000"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "10vh",
          marginBottom: "7vw",
        }}
      >
        <img
          src={mockup}
          alt="mockup"
          style={{
            borderRadius: "1%",
            width: "70%",
            boxShadow: "5px 5px 27px 5px rgba(22,119,255,0.38)",
          }}
        ></img>
      </div>

      {/*<div style={{ marginTop: "-5vh" }} className="spacer spacer_layer1"></div>*/}
      <Row
        ref={ref}
        justify="center"
        align="middle"
        style={{
          /*backgroundColor: "#1677ff",*/
          marginLeft: "0px",
          marginRight: "0px",
          paddingTop: "10vh",
          paddingBottom: "20vh",
          paddingLeft: "7vmax",
          paddingRight: "7vmax",
        }}
        gutter={32}
      >
        {cardsList.map((c, index) => (
          <Col
            key={index}
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay={c.delay}
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={6}
            style={{
              marginBottom: "3vh",
              marginTop: "3vh",
            }}
          >
            {" "}
            <InfoCard data={c.data} text={c.text} className="grow" />
          </Col>
        ))}
      </Row>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "8vh",
          paddingBottom: "20vh",
          textAlign: "center",
          paddingLeft: "15vmin",
          paddingRight: "15vmin",
        }}
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <Title
          level={1}
          strong
          className="gradient-title blur-title"
          style={{
            fontSize: Object.entries(screens)
              .filter((screen) => !!screen[1])
              .map((screen) => screen[0])
              .includes("md")
              ? "40pt"
              : "27pt",
          }}
        >
          Uncover Hidden <br />
          Trends
        </Title>
      </div>

      <Row
        gutter={{
          xs: 64,
          sm: 64,
          md: 64,
          lg: 64,
        }}
        style={{
          paddingLeft: "7vmax",
          paddingRight: "7vmax",
          marginBottom: "25vh",
        }}
      >
        {featureData.map((d, index) => (
          <div key={index}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-delay="0"
            >
              <FeatureCard
                icon={d.icon}
                name={d.name}
                text={d.text}
                path={d.path}
              />
            </Col>
          </div>
        ))}
      </Row>
      {/*<div className="spacer spacer_layer2"></div>*/}
      {/*<FAQSection />*/}
      {/*<ContactSection />*/}
      <div style={{ height: "5vh" }}></div>
    </>
  );
};

export default LandingPage;
