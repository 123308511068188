import { DotChartOutlined } from "@ant-design/icons";
import { Menu, Button, Tag, Divider, DatePicker, notification } from "antd";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/BlackLogoSmall.png";
import { useState, useEffect } from "react";
import "../../assets/styles/loading.scss";
import moment from "moment-timezone";

function Sidenav({ color }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(moment(new Date()));
  const [gameDates, setGameDates] = useState([]);

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: "Oops!",
      description:
        "Some games may not be available due to financial limations when computing winning probabilites.",
      placement: "bottomRight",
    });
  };

  useEffect(() => {
    getAllGames(
      "2023-03-15",
      moment(new Date()).add(1, "M").format("YYYY-MM-DD")
    ).then((res) => {
      setGameDates(res);
    });
    getData(date).then((res) => {
      setData(res);
      res.every((o) => {
        if (
          !(
            (o.period_name === "3rd" ||
              o.period_name === "SO" ||
              o.period_name === "OT") &
              (o.period_time === "00:00") ||
            o.period_name.includes("OT") & (o.score_1 !== o.score_2)
          )
        ) {
          openNotificationWithIcon("warning");
          return false;
        }
      });

      setLoading(false);
    });
  }, [date]);
  const signin = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
        fill={color}
      ></path>
    </svg>,
  ];

  return (
    <div>
      {contextHolder}
      <div>
        <NavLink to={"/"} style={{ color: "inherit" }}>
          <img style={{ width: "80%" }} src={logo} alt="" />
        </NavLink>
      </div>
      <hr />
      <div style={{ textAlign: "center" }}>
        <DatePicker
          value={date}
          allowClear={false}
          disabledDate={(current) => {
            return !gameDates.find(
              (date) =>
                date.format("YYYY-MM-DD") ===
                moment(current).format("YYYY-MM-DD")
            );
          }}
          style={{
            borderRadius: "10px",
            width: "90%",
            margin: "auto",
            height: "40px",
            marginBottom: "10px",
          }}
          onChange={(date) => {
            setDate(date);
            setLoading(true);
          }}
        />
      </div>
      <Menu theme="light">
        {loading === false ? (
          data.length === 0 ? (
            <div
              style={{
                textAlign: "center",
                width: "100%",
                marginTop: "15px",
              }}
            >
              {" "}
              <span>No games on {date.format("YYYY-MM-DD")}.</span>
            </div>
          ) : (
            data.map((o, index) => (
              <Menu.Item key={`/Game/${date.format("YYYY-MM-DD")}/${index}`}>
                <NavLink to={`/Game/${date.format("YYYY-MM-DD")}/${index}`}>
                  <span className="icon">{signin}</span>
                  <span className="label">
                    {o.abrev_1 + " vs. " + o.abrev_2}
                  </span>
                  {(o.period_name === "1st") & (o.period_time === "20:00") ? (
                    <Tag color="warning" style={{ marginLeft: "10px" }}>
                      Soon
                    </Tag>
                  ) : (o.period_name === "3rd" ||
                      o.period_name === "SO" ||
                      o.period_name === "OT") &
                      (o.period_time === "00:00") ||
                    o.period_name.includes("OT") & (o.score_1 !== o.score_2) ? (
                    <Tag color="error" style={{ marginLeft: "10px" }}>
                      Over
                    </Tag>
                  ) : (
                    <Tag color="success" style={{ marginLeft: "10px" }}>
                      Live
                    </Tag>
                  )}
                </NavLink>
              </Menu.Item>
            ))
          )
        ) : (
          <Menu.Item key="Loading">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <span
                className="skeleton-box"
                style={{ width: "80%", height: "35px", borderRadius: "6px" }}
              ></span>
              <span
                className="skeleton-box"
                style={{ width: "80%", height: "35px", borderRadius: "6px" }}
              ></span>
              <span
                className="skeleton-box"
                style={{ width: "80%", height: "35px", borderRadius: "6px" }}
              ></span>
              <span
                className="skeleton-box"
                style={{ width: "80%", height: "35px", borderRadius: "6px" }}
              ></span>
            </div>
          </Menu.Item>
        )}
        <Divider />
        {/*
          <Menu.Item key="/AGV">
            <NavLink to={"/AGV"}>
              <span className="icon">
                <DotChartOutlined />
              </span>
              <span className="label">Added Goal Value</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/PPI">
            <NavLink to={"/PPI"}>
              <span className="icon">
                <DotChartOutlined />
              </span>
              <span className="label">Player Pressure Index</span>
            </NavLink>
          </Menu.Item>
          */}
        <Menu.Item key="/GPI" disabled>
          <NavLink to={"/GPI"}>
            <span className="icon">
              <DotChartOutlined />
            </span>
            <span className="label">Goal Pressure Index</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/PowerRankings" disabled>
          <NavLink to={"/PowerRankings"}>
            <span className="icon">
              <DotChartOutlined />
            </span>
            <span className="label">Power Rankings</span>
          </NavLink>
        </Menu.Item>
      </Menu>

      <div className="aside-footer">
        <div
          className="footer-box"
          style={{
            background: color,
          }}
        >
          <h6>Can I trust this?</h6>
          <p>Give the paper a read</p>
          <a
            href="https://nhl-in-game-probability.s3.ca-central-1.amazonaws.com/Predicting_Outcome_Of_NHL_Games-2.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <Button type="primary" className="ant-btn-sm ant-btn-block">
              Publication
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Sidenav;

/* functoin to fetch current game */
async function getData(date) {
  return fetch(
    `https://s35wmhq7vk.execute-api.ca-central-1.amazonaws.com/staging/getWinningProbabilities?date=${date.format(
      "YYYY-MM-DD"
    )}`
  ).then((res) => {
    if (res.status === 400) {
      return [];
    }
    return res.json();
  });
}

/* function to get all games in between dates from NHL API */
async function getAllGames(startDate, endDate) {

  const games = await fetch(
      "https://api-web.nhle.com/v1/schedule/now"
  ).then(async (res) => {
    if (res.status === 400) {
      return [];
    }
    const data = await res.json()
    return data;
  });

  if (games.gameWeek.length !== 0) {
    return games.gameWeek.map((gweek) => gweek.games.map((game) => moment(game.startTimeUTC).utcOffset(-10))).flat()
  }
  return [];
}
