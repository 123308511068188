import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Main from "./components/layout/Main";
import "antd/dist/antd.min.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import LandingPage from "./pages/LandingPage";
import AOS from "aos";
import "aos/dist/aos.css";
import GoaliePressureIndex from "./pages/GoaliePressureIndex";
import PowerRankings from "./pages/PowerRankings";

function App() {
  AOS.init({ once: true });
  return (
    <div className="App">
      <Main>
        <Routes>
          <Route key={"LandingPage"} path="/" element={<LandingPage />} />
          <Route key={"GPI"} path="/GPI" element={<GoaliePressureIndex />} />
          <Route
            key={"PowerRankings"}
            path="/PowerRankings"
            element={<PowerRankings />}
          />
          <Route path="/Game/:date/:id" element={<Home />}></Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Main>
    </div>
  );
}

export default App;
