import { useState } from "react";
import { Layout, Drawer } from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header";
import Footer from "./Footer";
import ParticlesBackground from "../elements/ParticlesBackground";

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children }) {
  const [visible, setVisible] = useState(false);

  const openDrawer = () => setVisible(!visible);

  return (
    <Layout className={`layout-dashboard `}>
      <ParticlesBackground />

      <Drawer
        title={false}
        placement={"left"}
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        key={"left"}
        width={280}
        className={`drawer-sidebar`}
      >
        <Layout className={`layout-dashboard`}>
          <Sider
            trigger={null}
            width={260}
            theme="light"
            className={`sider-primary ant-layout-sider-primary `}
            style={{ background: "transparent" }}
          >
            <Sidenav color={"#1890ff"} />
          </Sider>
        </Layout>
      </Drawer>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        width={260}
        theme="light"
        className={`sider-primary ant-layout-sider-primary`}
        style={{ background: "transparent" }}
      >
        <Sidenav color={"#1890ff"} />
      </Sider>
      <Layout>
        <AntHeader className={`${false ? "ant-header-fixed" : ""}`}>
          <Header onPress={openDrawer} />
        </AntHeader>
        <Content className="content" style={{ marginLeft: "20px" }}>
          {children}
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
}

export default Main;
