import { useState, useEffect } from "react";

import { Card, Col, Row, Typography } from "antd";
import Echart from "../components/chart/EChart";
import LineChart from "../components/chart/LineChart";

import "../assets/styles/home.css";
import { useParams, Navigate } from "react-router-dom";
import MainLoader from "../components/elements/MainLoader";

function Home() {
  const { Title } = Typography;

  const [game, setGame] = useState({});
  const [loading, setLoading] = useState(true);
  const { date, id } = useParams();

  /* on page load */
  useEffect(() => {
    setLoading(true);
    getData(date, id).then((res) => {
      setGame(res);
      setLoading(false);
    });
  }, [date, id]);

  // if loading return loading screen
  if (loading) {
    return <MainLoader />;
  }

  if (game == null) {
    // here we could add a popup
    return <Navigate to="/" />;
  }

  function conditionalOverTimeProbability() {
    if (game.ot_flag === 1) {
      return (
        <Row gutter={[24, 0]}>
          <Col
            xs={24}
            sm={24}
            md={6}
            lg={8}
            xl={6}
            className="mb-24"
            data-aos="fade-up"
          >
            <Card bordered={false} className="criclebox h-full">
              <Echart
                time={new Date().toLocaleString()}
                game={game}
                loading={loading}
              />
            </Card>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={18}
            lg={16}
            xl={18}
            className="mb-24"
            data-aos="fade-up"
          >
            <Card bordered={false} className="criclebox h-full">
              <LineChart
                time={new Date().toLocaleString()}
                game={game}
                loading={loading}
              />
            </Card>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            className="mb-24"
            data-aos="fade-up"
          >
            <Card bordered={false} className="criclebox h-full">
              <LineChart
                time={new Date().toLocaleString()}
                game={game}
                loading={loading}
              />
            </Card>
          </Col>
        </Row>
      );
    }
  }

  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          <Col
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}
            className="mb-24"
            data-aos="fade-right"
          >
            <Card bordered={false} className="criclebox">
              <div
                style={{
                  backgroundColor: "#1677ff",
                  width: "20px",
                  height: "100%",
                  position: "absolute",
                  marginTop: "-16px",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  left: 0,
                }}
              ></div>
              <div className="number">
                <Row gutter={[24, 0]} align="middle" justify="center">
                  <Col span={1}></Col>
                  <Col span={19}>
                    <Title level={2} style={{ marginBottom: 0 }}>
                      {game.name_1}
                    </Title>
                    <span>{parseRecord(game.league_record_1, loading)}</span>
                  </Col>
                  <Col
                    span={3}
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <Title
                      level={1}
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      {loading === true ? "NA" : game.score_1}
                    </Title>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={2}
            lg={2}
            xl={2}
            className="mb-24"
            data-aos="fade-down"
          >
            <Card bordered={false} className="criclebox ">
              <Row align="middle" justify="center">
                <Title level={3}>{game.period_name}</Title>
              </Row>
              <Row align="middle" justify="center">
                {game.period_time}
              </Row>
            </Card>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}
            className="mb-24"
            data-aos="fade-left"
          >
            <Card bordered={false} className="criclebox ">
              <div
                style={{
                  backgroundColor: "orange",
                  width: "20px",
                  height: "100%",
                  position: "absolute",
                  marginTop: "-16px",
                  borderTopRightRadius: "10px",
                  borderBottomRightRadius: "10px",
                  right: 0,
                }}
              ></div>
              <div className="number">
                <Row gutter={[24, 0]} align="middle" justify="center">
                  <Col
                    span={3}
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <Title
                      level={1}
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      {loading === true ? "NA" : game.score_2}
                    </Title>
                  </Col>
                  <Col
                    span={19}
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <Title level={2} style={{ marginBottom: 0 }}>
                      {game.name_2}
                    </Title>
                    <span>{parseRecord(game.league_record_2, loading)}</span>
                  </Col>
                  <Col span={1}></Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {getCount(game, loading).map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="mb-24"
              data-aos="fade-in"
            >
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>{c.today}</span>
                      <Title level={3}>
                        {c.title} <small className={c.bnb}>{c.persent}</small>
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box" style={{ background: c.color }}>
                        <Title style={{ color: "white" }} level={1}>
                          {c.icon}
                        </Title>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        {conditionalOverTimeProbability()}
        {/*<StatsCard
					title="Shots"
					team1Shots={game.shots_1}
					team2Shots={game.shots_2}
				/>*/}
      </div>
    </>
  );
}

export default Home;

/* function to get elment from the back */
function getElementFromBack(game, team, index) {
  var array = Object.values(JSON.parse(game.probabilities)[team]);
  if (index > array.length) {
    return 0;
  }
  return Math.round(array[array.length - index] * 100);
}

/* functoin to fetch current game */
async function getData(date, id) {
  return fetch(
    `https://s35wmhq7vk.execute-api.ca-central-1.amazonaws.com/staging/getWinningProbabilities?date=${date}&id=${id}`
  ).then((res) => {
    if (res.status === 400) {
      return null;
    }
    return res.json();
  });
}

/* function to get count */
function getCount(game, loading) {
  if (loading) {
    return [];
  }
  const count = [
    {
      today: game.abrev_1 + " - Win Probability",
      title:
        loading === true ? "NA" : getElementFromBack(game, "win_1", 1) + "%",
      persent:
        loading === true
          ? "NA"
          : getElementFromBack(game, "win_1", 1) -
            getElementFromBack(game, "win_1", 5) +
            "%",
      icon: game.name_1[0] ?? "N",
      bnb:
        getElementFromBack(game, "win_1", 1) -
          getElementFromBack(game, "win_1", 5) >=
        0
          ? "bnb2"
          : "redtext",
      color: "#1677ff",
    },

    {
      today: "Overtime Probability",
      title:
        loading === true ? "NA" : getElementFromBack(game, "overtime", 1) + "%",
      persent:
        loading === true
          ? "NA"
          : getElementFromBack(game, "overtime", 1) -
            getElementFromBack(game, "overtime", 5) +
            "%",
      icon: "O",
      bnb:
        getElementFromBack(game, "overtime", 1) -
          getElementFromBack(game, "overtime", 5) >=
        0
          ? "bnb2"
          : "redtext",
      color: "#73d13d",
    },

    {
      today: game.abrev_2 + " - Win Probability",
      title:
        loading === true ? "NA" : getElementFromBack(game, "win_2", 1) + "%",
      persent:
        loading === true
          ? "NA"
          : getElementFromBack(game, "win_2", 1) -
            getElementFromBack(game, "win_2", 5) +
            "%",
      icon: game.name_2[0],
      bnb:
        getElementFromBack(game, "win_2", 1) -
          getElementFromBack(game, "win_2", 5) >=
        0
          ? "bnb2"
          : "redtext",
      color: "Orange",
    },
  ];

  return count;
}

/* function to parse league record */
function parseRecord(record, loading) {
  if (loading) {
    return "";
  }
  const recordString =
    (record.wins ?? 0) +
    " - " +
    (record.losses ?? 0) +
    " - " +
    (record.ot ?? 0);
  return recordString;
}
