import React from "react";
import { Card, Typography } from "antd";

const { Title } = Typography;

const cardStyle = {
  borderRadius: 20,
  boxShadow:
    "0 4px 12px rgba(0, 0, 0, 0.15), 0 4px 30px rgba(0, 0, 0, 0.1), 0 12px 40px rgba(0, 0, 0, 0.05), 0 8px 80px rgba(0, 0, 0, 0.025)",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
  color: "#000000",
};

const numberStyle = {
  fontSize: "7.6vmin",
  fontWeight: "bold",
  marginBottom: 0,
  textAlign: "center",
  background: "rgb(2, 0, 36)",
  background:
    "linear-gradient(162deg,rgba(2, 0, 36, 1) 0%,rgba(77, 77, 255, 1) 0%,rgba(0, 212, 255, 1) 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textShadow: "0 4px 12px rgba(255, 255, 255, 0.25)",
};

const textStyle = {
  fontSize: "1.8	vmin",
  textAlign: "center",
};

export default function ({ data, text }) {
  return (
    <Card style={cardStyle} className="grow">
      <Title style={numberStyle}>{data}</Title>
      <p style={textStyle}>{text}</p>
    </Card>
  );
}
