import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography } from "antd";

function EChart({ time, game, loading }) {
  const { Title, Paragraph } = Typography;

  const items = [
    {
      Title:
        Object.values(JSON.parse(game.probabilities).time).length < 30
          ? "NA"
          : Math.round(game.ot_1 * 100) + "%",
      user: game.abrev_1,
    },
    {
      Title:
        Object.values(JSON.parse(game.probabilities).time).length < 30
          ? "NA"
          : Math.round(game.ot_shootout * 100) + "%",
      user: "Shootout",
    },
    {
      Title:
        Object.values(JSON.parse(game.probabilities).time).length < 30
          ? "NA"
          : Math.round(game.ot_2 * 100) + "%",
      user: game.abrev_2,
    },
  ];

  var options = {
    labels: [game.abrev_1, "Shootout", game.abrev_2],
    chart: {
      type: "donut",
    },
    legend: {
      show: false,
      position: "bottom",
    },
    dataLabels: {
      enabled: false,
    },
    noData: {
      text: ["OT Probabilites are active", " from the 30 minute mark...	"],
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#000000",
        fontSize: "14px",
        fontFamily: "Helvetica",
      },
    },
  };

  function getSeries(loading) {
    if (
      loading ||
      Object.values(JSON.parse(game.probabilities).time).length < 30
    ) {
      return [];
    } else {
      console.log();
      return [
        Math.round(game.ot_1 * 100),
        Math.round(game.ot_shootout * 100),
        Math.round(game.ot_2 * 100),
      ];
    }
  }

  return (
    <>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={getSeries(loading)}
          type="donut"
        />
      </div>
      <div className="chart-vistior">
        <Title level={5}>Overtime Win Probability</Title>
        <Paragraph className="lastweek">
          Last Updated: <span className="bnb2">{time.split([","])[1]}</span>
        </Paragraph>
        <Paragraph className="lastweek" style={{ textAlign: "justify" }}>
          The overtime win probability takes into account the win probabilities
          during regular time as well as expected game stats at the end of
          regular time.
        </Paragraph>
        <Row gutter>
          {items.map((v, index) => (
            <Col xs={8} xl={8} sm={8} md={8} key={index}>
              <div
                className="chart-visitor-count"
                style={{ textAlign: "center" }}
              >
                <Title level={4}>{v.Title}</Title>
                <span>{v.user}</span>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

export default EChart;
