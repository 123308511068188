import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";

function LineChart({ time, game, loading }) {
  const { Title, Paragraph } = Typography;

  function getScoreLines() {
    /* if score_times_2 is not a key in the object game return an empty array */
    if (!game.score_times_2) {
      return [];
    }

    var team_2 = game.score_times_2.map((t) => {
      return {
        x: t,
        strokeDashArray: 0,
        borderColor: "Orange",
        label: {
          borderColor: "Orange",
          style: {
            color: "#fff",
            background: "Orange",
          },
          text: game.abrev_2 + " Scored",
        },
      };
    });

    var team_1 = game.score_times_1.map((t) => {
      return {
        x: t,
        strokeDashArray: 0,
        borderColor: "#1677ff",
        label: {
          borderColor: "#1677ff",
          style: {
            color: "#fff",
            background: "#1677ff",
          },
          text: game.abrev_1 + " Scored",
        },
      };
    });

    return team_1.concat(team_2);
  }

  function getSeries(loading) {
    if (loading) {
      return [];
    } else {
      var series = [
        {
          name: game.name_1,
          data: Object.values(JSON.parse(game.probabilities).win_1),
          offsetY: 0,
        },
        {
          name: "Overtime",
          data: Object.values(JSON.parse(game.probabilities).overtime),
          offsetY: 0,
        },
        {
          name: game.name_2,
          data: Object.values(JSON.parse(game.probabilities).win_2),
          offsetY: 0,
        },
      ];
      return series;
    }
  }

  function getOptions() {
    var option = {
      annotations: {
        xaxis: getScoreLines(),
      },
      chart: {
        width: "100%",
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },

        zoom: {
          enabled: false,
        },
      },
      grid: {
        padding: {
          bottom: 0,
        },
      },

      legend: {
        show: false,
        position: "top",
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },

      yaxis: {
        labels: {
          style: {
            fontSize: "14px",
            fontWeight: 600,
            colors: ["#8c8c8c"],
          },
        },
        min: 0,
        max: 1,
      },
      noData: {
        text: "The current winning chances are being calculated...	",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#000000",
          fontSize: "14px",
          fontFamily: "Helvetica",
        },
      },

      xaxis: {
        labels: {
          style: {
            fontSize: "14px",
            fontWeight: 600,
            colors: [
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
            ],
          },
          rotate: -0,
          offsetY: 5,
        },
        min: 0,
        max: 60,

        categories: [
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
          37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53,
          54, 55, 56, 57, 58, 59, 60,
        ],
      },

      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    };
    return option;
  }

  return (
    <>
      <div className="linechart">
        <div>
          <Title level={5}>Live Winning Probability</Title>
          <Paragraph className="lastweek">
            Last updated: <span className="bnb2">{time.split([","])[1]}</span>
          </Paragraph>
        </div>
      </div>

      <ReactApexChart
        className="full-width"
        options={getOptions()}
        series={getSeries(loading)}
        type="area"
        height={380}
        width={"100%"}
      />
    </>
  );
}

export default LineChart;
