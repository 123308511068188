import { Card } from "antd";
import React from "react";
import PowerRankingsTable from "../components/elements/PowerRankingsTable";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";

const PowerRankings = () => {
  return (
    <>
      <Card className="mb-24 ">
        <Title>Understanding The Power Ratings</Title>
      </Card>
      <Card data-aos="fade-up" data-aos-duration="500">
        <div className="linechart">
          <div>
            <Title level={5}>Power Ratings</Title>
            <Paragraph className="lastweek">Updated Once A Week</Paragraph>
          </div>
        </div>
        <PowerRankingsTable />
      </Card>
    </>
  );
};

export default PowerRankings;
