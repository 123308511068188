import React from "react";
import { Table } from "antd";

const DynamicColumnsTable = (props) => {
	const { columns, height, rowKey, source, width } = props;

	return (
		<Table
			rowClassName="custom-row-hover row-animation"
			columns={columns}
			dataSource={source}
			rowKey={rowKey}
			pagination={false}
			scroll={{ x: width, y: 400 }}
		/>
	);
};

export default DynamicColumnsTable;
